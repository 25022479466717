import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Edit2, Save, X, ChevronLeft } from "lucide-react";
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  query,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../services/firebase";
import suguThemeColors from "../theme/suguThemeColors";
import Spinner from 'react-bootstrap/Spinner';

const ParkingSlotDetailEdit = () => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [parkingSlot, setParkingSlot] = useState(null);
  const [formData, setFormData] = useState(null);
  const [airports, setAirports] = useState([]);
  const [selectedAirport, setSelectedAirport] = useState(null);
  const [loading , setLoading] = useState(false)

  // Extract slot ID from URL
  const slotId = window.location.pathname.split("/").pop();

  // Fetch airports and parking slot details
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Fetch Airports
        const airportsQuery = query(collection(db, "airports"));
        const airportsSnapshot = await getDocs(airportsQuery);
        const airportsList = airportsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAirports(airportsList);

        // Fetch Parking Slot Details
        const docRef = doc(db, "parkingSlots", slotId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();

          // Find and set the selected airport
          const currentAirport = airportsList.find(
            (airport) => airport.id === data.airport_id
          );
          console.log(JSON.stringify(data), "datadatadatadata");
          setParkingSlot(data);
          setFormData(data);
          setSelectedAirport(currentAirport);
        } else {
          console.error("No such document!");
          navigate("/parking-slots");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/parking-slots");
      }
    };

    fetchInitialData();
  }, [slotId, navigate]);

  // Handle airport selection
  const handleAirportChange = (airportId) => {
    const airport = airports.find((a) => a.id === airportId);
    setSelectedAirport(airport);

    // Reset drop-off and return procedures when airport changes
    setFormData((prev) => ({
      ...prev,
      airport_id: airportId,
      dropoff_procedure: {
        terminals: airport
          ? airport.terminals.map((t) => ({
              terminal_name: t,
              car_park_address: "",
              departure_procedure: "",
            }))
          : [],
        departureProcedure: "",
      },
      return_procedure: {
        terminals: airport
          ? airport.terminals.map((t) => ({
              terminal_name: t,
              car_park_address: "",
              return_procedure: "",
            }))
          : [],
        departureProcedure: "",
      },
    }));
  };

  // Generic input change handler
  const handleInputChange = (
    e,
    section,
    field,
    isArray = false,
    arrayIndex = null
  ) => {
    const value = e.target.value;
    console.log(value, "its updatedddd", section, field);

    setFormData((prev) => {
      // Convert the section string into a path array
      const path = section ? section.split(".") : [];
      const updatedData = { ...prev };
      let current = updatedData;

      // Navigate to the correct part of the object
      for (let i = 0; i < path.length - 1; i++) {
        const key = path[i];
        current[key] = { ...current[key] }; // Ensure immutability
        current = current[key];
      }

      // Handle array updates
      if (isArray && arrayIndex !== null) {
        const arrayKey = path[path.length - 1];
        current[arrayKey] = [...(current[arrayKey] || [])];
        current[arrayKey][arrayIndex] = {
          ...current[arrayKey][arrayIndex],
          [field]: value,
        };
      } else if (section) {
        const finalKey = path[path.length - 1];
        current[finalKey] = {
          ...current[finalKey],
          [field]: value,
        };
      } else {
        updatedData[field] = value;
      }

      return updatedData;
    });
  };

  // Save changes
  const handleSave = async () => {
    setLoading(true)
    try {
      const docRef = doc(db, "parkingSlots", slotId);
      await updateDoc(docRef, formData);

      alert("Parking slot updated successfully!");

      setParkingSlot(formData);
      setIsEditing(false);
      setLoading(false)
    } catch (error) {
      console.error("Error updating document:", error);
      alert("Failed to update parking slot");
      setLoading(false)
    }
  };

  // Delete parking slot
  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this parking slot?")) {
      try {
        const docRef = doc(db, "parkingSlots", slotId);
        await deleteDoc(docRef);

        alert("Parking slot deleted successfully!");
        navigate("/parking-slots");
      } catch (error) {
        console.error("Error deleting document:", error);
        alert("Failed to delete parking slot");
      }
    }
  };

  if (!parkingSlot || !selectedAirport) return <div>Loading...</div>;

  return (
    <div className="p-6">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center space-x-4">
          <button
            onClick={() => navigate("/parking-slots")}
            className="p-2 rounded-full hover:bg-subtle-navy-100"
          >
            <ChevronLeft
              className="w-6 h-6"
              style={{ color: suguThemeColors.primary.navy }}
            />
          </button>
          <h1
            className="text-2xl font-bold"
            style={{ color: suguThemeColors.primary.navy }}
          >
            {parkingSlot.name} Details
          </h1>
        </div>
        <div className="flex space-x-2">
          {!isEditing ? (
            <>
              <button
                onClick={() => setIsEditing(true)}
                className="flex items-center space-x-2 px-4 py-2 rounded"
                style={{
                  backgroundColor: suguThemeColors.primary.orange,
                  color: suguThemeColors.neutral.white,
                }}
              >
                <Edit2 className="w-5 h-5" />
                <span>Edit</span>
              </button>
              <button
                onClick={handleDelete}
                className="flex items-center space-x-2 px-4 py-2 rounded"
                style={{
                  backgroundColor: suguThemeColors.status.error.light,
                  color: suguThemeColors.status.error.dark,
                }}
              >
                <X className="w-5 h-5" />
                <span>Delete</span>
              </button>
            </>
          ) : (
            <button
            onClick={handleSave}
            className={`flex items-center space-x-2 px-4 py-2 rounded ${
              loading ? "cursor-not-allowed opacity-70" : ""
            }`}
            style={{
              backgroundColor: suguThemeColors.status.success.light,
              color: suguThemeColors.status.success.dark,
            }}
            disabled={loading} // Disable the button during loading
          >
            {loading ? (
              <>
                <Spinner className="w-5 h-5 animate-spin" /> {/* Replace with your spinner component */}
                <span>Saving...</span>
              </>
            ) : (
              <>
                <Save className="w-5 h-5" />
                <span>Save Changes</span>
              </>
            )}
          </button>
          )}
        </div>
      </div>

      {/* Main Content Grid */}
      <div className="grid md:grid-cols-2 gap-6">
        {/* Airport Selection */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2
            className="text-xl font-semibold mb-4"
            style={{ color: suguThemeColors.primary.navy }}
          >
            Airport Information
          </h2>
          {isEditing ? (
            <div>
              <label
                className="block mb-2 font-medium"
                style={{ color: suguThemeColors.primary.navy }}
              >
                Select Airport
              </label>
              <select
                value={formData.airport_id || ""}
                onChange={(e) => handleAirportChange(e.target.value)}
                className="w-full px-3 py-2 border rounded"
              >
                <option value="">Select an Airport</option>
                {airports.map((airport) => (
                  <option key={airport.id} value={airport.id}>
                    {airport.name} - 
                    {/* {airport.location} */}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <p>
              {selectedAirport.name} - 
              {/* {selectedAirport.location} */}
            </p>
          )}
        </div>

        {/* Basic Information */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2
            className="text-xl font-semibold mb-4"
            style={{ color: suguThemeColors.primary.navy }}
          >
            Basic Information
          </h2>
          {["name", "type", "capacity", "pricing"].map((field) => (
            <div key={field} className="mb-4">
              <label
                className="block mb-2 font-medium"
                style={{ color: suguThemeColors.primary.navy }}
              >
                {field.charAt(0).toUpperCase() + field.slice(1)}
              </label>
              {isEditing ? (
                <input
                  type={
                    field === "capacity" || field === "pricing"
                      ? "number"
                      : "text"
                  }
                  value={formData[field] || ""}
                  onChange={(e) => handleInputChange(e, null, field)}
                  className="w-full px-3 py-2 border rounded"
                />
              ) : (
                <p>{parkingSlot[field]}</p>
              )}
            </div>
          ))}
        </div>

        {/* Drop-off Procedure */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2
            className="text-xl font-semibold mb-4"
            style={{ color: suguThemeColors.primary.navy }}
          >
            Drop-off Procedure
          </h2>
          <div className="mb-4">
            <label
              className="block mb-2 font-medium"
              style={{ color: suguThemeColors.primary.navy }}
            >
              Overall Departure Procedure
            </label>
            {isEditing ? (
              <input
                type="text"
                value={formData.dropoff_procedure?.departureProcedure || ""}
                onChange={(e) =>
                  handleInputChange(
                    e,
                    "dropoff_procedure",
                    "departureProcedure"
                  )
                }
                className="w-full px-3 py-2 border rounded"
              />
            ) : (
              <p>{parkingSlot.dropoff_procedure?.departureProcedure}</p>
            )}
          </div>

          {/* Terminal Details */}
          {formData.dropoff_procedure?.terminals?.map((terminal, index) => (
            <div key={index} className="mb-4 p-4 bg-gray-50 rounded">
              <h3 className="font-semibold mb-2">{terminal.terminal_name}</h3>
              {isEditing ? (
                <>
                  <div className="mb-2">
                    <label>Car Park Address</label>
                    <input
                      type="text"
                      value={terminal.car_park_address || ""}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "dropoff_procedure.terminals",
                          "car_park_address",
                          true,
                          index
                        )
                      }
                      className="w-full px-3 py-2 border rounded"
                    />
                  </div>
                  <div>
                    <label>Departure Procedure</label>
                    <input
                      type="text"
                      value={terminal.departure_procedure || ""}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "dropoff_procedure.terminals",
                          "departure_procedure",
                          true,
                          index
                        )
                      }
                      className="w-full px-3 py-2 border rounded"
                    />
                  </div>
                </>
              ) : (
                <>
                  <p>Car Park Address: {terminal.car_park_address}</p>
                  <p>Departure Procedure: {terminal.departure_procedure}</p>
                </>
              )}
            </div>
          ))}
        </div>

        {/* Return Procedure */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2
            className="text-xl font-semibold mb-4"
            style={{ color: suguThemeColors.primary.navy }}
          >
            Return Procedure
          </h2>
          <div className="mb-4">
            <label
              className="block mb-2 font-medium"
              style={{ color: suguThemeColors.primary.navy }}
            >
              Overall Return Procedure
            </label>
            {isEditing ? (
              <input
                type="text"
                value={formData.return_procedure?.departureProcedure || ""}
                onChange={(e) =>
                  handleInputChange(e, "return_procedure", "departureProcedure")
                }
                className="w-full px-3 py-2 border rounded"
              />
            ) : (
              <p>{parkingSlot.return_procedure?.departureProcedure}</p>
            )}
          </div>

          {/* Return Terminal Details */}
          {formData.return_procedure?.terminals?.map((terminal, index) => (
            <div key={index} className="mb-4 p-4 bg-gray-50 rounded">
              <h3 className="font-semibold mb-2">{terminal.terminal_name}</h3>
              {isEditing ? (
                <>
                  <div className="mb-2">
                    <label>Car Park Address</label>
                    <input
                      type="text"
                      value={terminal.car_park_address || ""}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "return_procedure.terminals",
                          "car_park_address",
                          true,
                          index
                        )
                      }
                      className="w-full px-3 py-2 border rounded"
                    />
                  </div>
                  <div>
                    <label>Return Procedure</label>
                    <input
                      type="text"
                      value={terminal.return_procedure || ""}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "return_procedure.terminals",
                          "return_procedure",
                          true,
                          index
                        )
                      }
                      className="w-full px-3 py-2 border rounded"
                    />
                  </div>
                </>
              ) : (
                <>
                  <p>Car Park Address: {terminal.car_park_address}</p>
                  <p>Return Procedure: {terminal.return_procedure}</p>
                </>
              )}
            </div>
          ))}
        </div>

        {/* Overview Details */}
        <div className="bg-white rounded-lg shadow p-6 md:col-span-2">
          <h2
            className="text-xl font-semibold mb-4"
            style={{ color: suguThemeColors.primary.navy }}
          >
            Overview Details
          </h2>
          <div className="grid md:grid-cols-2 gap-4">
            {Object.entries(parkingSlot.overview || {}).map(([key, value]) => (
              <div key={key}>
                <label
                  className="block mb-2 font-medium"
                  style={{ color: suguThemeColors.primary.navy }}
                >
                  {key.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
                    return str.toUpperCase();
                  })}
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    value={formData.overview[key] || ""}
                    onChange={(e) => handleInputChange(e, "overview", key)}
                    className="w-full px-3 py-2 border rounded"
                  />
                ) : (
                  <p>{value || "N/A"}</p>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Features Overview */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2
            className="text-xl font-semibold mb-4"
            style={{ color: suguThemeColors.primary.navy }}
          >
            Features Overview
          </h2>
          <div className="space-y-4">
            {Object.entries(parkingSlot.features?.overview || {}).map(
              ([key, value]) => {
                // Restrict specific keys
                if (key === "address") return null; // Skip rendering "address"

                return (
                  <div key={key}>
                    <label
                      className="block mb-2 font-medium"
                      style={{ color: suguThemeColors.primary.navy }}
                    >
                      {key
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, (str) => str.toUpperCase())}
                    </label>
                    {isEditing ? (
                      key === "cancellationCoverAvailable" ? (
                        <select
                          value={formData.features.overview[key]}
                          onChange={(e) =>
                            handleInputChange(e, "features.overview", key)
                          }
                          className="w-full px-3 py-2 border rounded"
                        >
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      ) : (
                        <input
                          type="text"
                          value={formData.features.overview[key] || ""}
                          onChange={(e) =>
                            handleInputChange(e, "features.overview", key)
                          }
                          className="w-full px-3 py-2 border rounded"
                        />
                      )
                    ) : (
                      <p>
                        {typeof value === "boolean"
                          ? value
                            ? "Yes"
                            : "No"
                          : typeof value === "object"
                          ? JSON.stringify(value) // Handle objects by converting them to strings
                          : value || "N/A"}
                      </p>
                    )}
                  </div>
                );
              }
            )}
          </div>
        </div>

        {/* Address Details */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2
            className="text-xl font-semibold mb-4"
            style={{ color: suguThemeColors.primary.navy }}
          >
            Address Details
          </h2>
          <div className="space-y-4">
            {Object.entries(parkingSlot.features.overview.address || {}).map(
              ([key, value]) => (
                <div key={key}>
                  <label
                    className="block mb-2 font-medium"
                    style={{ color: suguThemeColors.primary.navy }}
                  >
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                  </label>
                  {isEditing ? (
                    <input
                      type="text"
                      value={formData.features.overview.address[key] || ""}
                      onChange={(e) =>
                        handleInputChange(e, "features.overview.address", key)
                      }
                      className="w-full px-3 py-2 border rounded"
                    />
                  ) : (
                    <p>{value || "N/A"}</p>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParkingSlotDetailEdit;
