import React, { useState, useEffect } from "react";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db } from "../services/firebase";
import AddAirport from "../components/addAirport";
import { Edit2, Trash2 } from "lucide-react"; // Using lucide icons
import suguThemeColors from "../theme/suguThemeColors";

// const createUser = async (userData) => {
//     try {
//       const newUser = {
//         ...userData,
//         created_at: Timestamp.now(),
//         updated_at: Timestamp.now(),
//       };

//       const docRef = await addDoc(collection(db, "users"), newUser);
//       console.log("User created with ID: ", docRef.id);
//     } catch (error) {
//       console.error("Error creating user: ", error);
//     }
//   };

{
  /* <td className="p-3">
  {user.created_at
    ? new Timestamp(user.created_at.seconds, user.created_at.nanoseconds)
        .toDate()
        .toLocaleString()
    : "N/A"}
</td>
<td className="p-3">
  {user.updated_at
    ? new Timestamp(user.updated_at.seconds, user.updated_at.nanoseconds)
        .toDate()
        .toLocaleString()
    : "N/A"}
</td> */
}

const Airports = () => {
    const [airports, setAirports] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortAscending, setSortAscending] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editingAirport, setEditingAirport] = useState(null);
  
    const fetchAirports = async () => {
      const data = await getDocs(collection(db, "airports"));
      setAirports(
        data.docs.map((doc) => {
          const docData = doc.data();
          return {
            id: doc.id,
            ...docData,
            // Ensure location is always a simple object with primitive values
            location: {
              lat: docData.location?.lat ?? null,
              lng: docData.location?.lng ?? null,
              name: docData.location?.name ?? 'Unknown Location'
            },
            // Convert location to a string for rendering
            locationDisplay: (() => {
              const loc = docData.location;
              if (loc?.name) return loc.name;
              if (loc?.lat && loc?.lng) return `${loc.lat}, ${loc.lng}`;
              return 'Location not available';
            })(),
            // Ensure terminals is always an array
            terminals: Array.isArray(docData.terminals) ? docData.terminals : []
          };
        })
      );
    };
    
    useEffect(() => {
      fetchAirports();
    }, []);
  
    const handleDelete = async (id) => {
      if (!id) {
        console.error("Invalid ID passed to delete function.");
        return;
      }
      setLoading(true);
      try {
        await deleteDoc(doc(db, "airports", id));
        setAirports((prev) => prev.filter((airport) => airport.id !== id));
      } catch (error) {
        console.error("Error deleting airport:", error);
      } finally {
        setLoading(false);
      }
    };
  
    const handleSort = () => {
      setSortAscending(!sortAscending);
      setAirports((prev) =>
        [...prev].sort((a, b) =>
          sortAscending
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name)
        )
      );
    };
  
    const handleSearch = (e) => {
      setSearchTerm(e.target.value.toLowerCase());
    };
  
    const filteredAirports = airports.filter((airport) =>
      airport.name.toLowerCase().includes(searchTerm)
    );
  
    const handleEdit = (airport) => {
      setEditingAirport(airport);
      setShowAddModal(true);
    };
  
    const tableCellStyle = {
      padding: "16px",
      borderBottom: `1px solid ${suguThemeColors.table.border}`,
    };
  
    const buttonStyles = {
      primary: {
        background: suguThemeColors.primary.orange,
        color: suguThemeColors.neutral.white,
        padding: "12px 24px",
        borderRadius: "6px",
        border: "none",
        cursor: "pointer",
        fontWeight: "600",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        transition: "background 0.3s ease",
        hover: {
          background: suguThemeColors.secondary.darkOrange,
        },
      },
      iconButton: {
        padding: "8px",
        borderRadius: "4px",
        border: "none",
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "all 0.3s ease",
      },
    };
  
    return (
      <div
        style={{
          padding: "32px",
          backgroundColor: suguThemeColors.subtle.navy[50],
          minHeight: "100vh",
          fontFamily: "system-ui, -apple-system, sans-serif",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "32px",
          }}
        >
          <h1
            className="text-2xl font-bold"
            style={{
              color: suguThemeColors.primary.navy,
            }}
          >
            Manage Airports
          </h1>
  
          <button
            style={{
              ...buttonStyles.primary,
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
            onMouseOver={(e) =>
              (e.target.style.background = buttonStyles.primary.hover.background)
            }
            onMouseOut={(e) =>
              (e.target.style.background = buttonStyles.primary.background)
            }
            onClick={() => setShowAddModal(!showAddModal)}
          >
            {showAddModal ? "Close" : "Add New Airport"}
          </button>
        </div>
        {showAddModal && (
          <AddAirport
            onClose={() => {
              setShowAddModal(false);
              setEditingAirport(null);
            }}
            onAirportAdded={(newAirport) => {
              if (editingAirport) {
                // Update existing airport in the list
                setAirports((currentAirports) =>
                  currentAirports.map((airport) =>
                    airport.id === newAirport.id 
                      ? {
                          ...newAirport,
                          locationDisplay: (() => {
                            const loc = newAirport.location;
                            if (loc?.name) return loc.name;
                            if (loc?.lat && loc?.lng) return `${loc.lat}, ${loc.lng}`;
                            return 'Location not available';
                          })()
                        } 
                      : airport
                  )
                );
              } else {
                // Add new airport to the list
                setAirports((currentAirports) => [
                  {
                    ...newAirport,
                    locationDisplay: (() => {
                      const loc = newAirport.location;
                      if (loc?.name) return loc.name;
                      if (loc?.lat && loc?.lng) return `${loc.lat}, ${loc.lng}`;
                      return 'Location not available';
                    })()
                  },
                  ...currentAirports,
                ]);
              }
              setEditingAirport(null);
            }}
            existingAirport={editingAirport}
          />
        )}
  
        {/* Rest of the component remains the same... */}
  
        <div
          style={{
            background: suguThemeColors.neutral.white,
            borderRadius: "12px",
            overflow: "hidden",
            boxShadow: "0 4px 6px rgba(0,0,0,0.05)",
          }}
        >
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              {/* Table header remains the same */}
            </thead>
            <tbody>
              {filteredAirports.map((airport, index) => (
                <tr
                  key={airport.id}
                  style={{
                    backgroundColor:
                      index % 2 === 0
                        ? suguThemeColors.table.rowEven
                        : suguThemeColors.table.rowOdd,
                    transition: "background-color 0.2s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      suguThemeColors.table.rowHover)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      index % 2 === 0
                        ? suguThemeColors.table.rowEven
                        : suguThemeColors.table.rowOdd)
                  }
                >
                  <td style={tableCellStyle}>{airport.name}</td>
                  <td style={tableCellStyle}>
                    {/* Use the pre-processed locationDisplay string */}
                    {airport.locationDisplay}
                  </td>
                  <td style={tableCellStyle}>
                    {Array.isArray(airport.terminals) && airport.terminals.length > 0 ? (
                      airport.terminals.map((terminal, i) => (
                        <span
                          key={i}
                          style={{
                            display: "inline-block",
                            padding: "4px 8px",
                            margin: "0 4px 4px 0",
                            backgroundColor: suguThemeColors.subtle.navy[100],
                            color: suguThemeColors.primary.navy,
                            borderRadius: "4px",
                            fontSize: "14px",
                          }}
                        >
                          {terminal}
                        </span>
                      ))
                    ) : (
                      <span
                        style={{
                          color: suguThemeColors.primary.navy,
                          fontStyle: "italic",
                        }}
                      >
                        No terminals available
                      </span>
                    )}
                  </td>
                <td style={{ ...tableCellStyle, textAlign: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      style={{
                        ...buttonStyles.iconButton,
                        backgroundColor: suguThemeColors.subtle.navy[100],
                        color: suguThemeColors.primary.navy,
                      }}
                      onClick={() => handleEdit(airport)}
                      title="Edit airport"
                    >
                      <Edit2 size={16} />
                    </button>
                    <button
                      style={{
                        ...buttonStyles.iconButton,
                        backgroundColor: suguThemeColors.status.error.light,
                        color: suguThemeColors.status.error.dark,
                      }}
                      onClick={() => handleDelete(airport.id)}
                      title="Delete airport"
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Airports;
