import React, { useState, useEffect } from "react";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../services/firebase";
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import suguThemeColors from "../theme/suguThemeColors";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet-geosearch/dist/geosearch.css";

// Set default Leaflet icon
L.Marker.prototype.options.icon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

const SearchControl = ({ onLocationSelect }) => {
  const map = useMap();
  const provider = new OpenStreetMapProvider();

  useEffect(() => {
    const searchControl = new GeoSearchControl({
      provider,
      style: "bar",
      showMarker: false,
      retainZoomLevel: false,
      searchLabel: "Search",
    });

    map.addControl(searchControl);

    const handleLocationSelect = (event) => {
        if (!event.result) {
          console.error("No result found for the selected location:", event);
          return;
        }
      
        const { x: lng, y: lat, label: name } = event.result;
      
        if (lat === undefined || lng === undefined) {
          console.error("Invalid coordinates in event result:", event.result);
          return;
        }
      
        onLocationSelect({ coordinates: [lat, lng], name });
        map.setView([lat, lng], 10);
      };

    map.on("geosearch/showlocation", handleLocationSelect);
    return () => {
      map.removeControl(searchControl);
      map.off("geosearch/showlocation", handleLocationSelect);
    };
  }, [map, onLocationSelect]);

  return null;
};

const AddAirport = ({ onClose, onAirportAdded, existingAirport = null }) => {
  const [airport, setAirport] = useState({
    name: existingAirport?.name || "",
    location:
      existingAirport?.location && existingAirport.location.coordinates
        ? existingAirport.location
        : {
            coordinates: [51.5074, -0.1278], // Default: London
            name: "London, UK",
          },
    terminals: existingAirport?.terminals || [],
  });

  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleLocationSelect = (locationData) => {
    setAirport((prev) => ({
      ...prev,
      location: locationData,
    }));
    setFormErrors((prev) => ({ ...prev, location: undefined }));
  };
 

  const LocationSelector = () => {
    useMapEvents({
      click: async ({ latlng }) => {
        const { lat, lng } = latlng;
        try {
          const provider = new OpenStreetMapProvider();
          const results = await provider.search({ query: `${lat},${lng}` });
          const locationName = results[0]?.label || `${lat}, ${lng}`;
          handleLocationSelect({
            coordinates: [lat, lng],
            name: locationName,
          });
        } catch {
          handleLocationSelect({
            coordinates: [lat, lng],
            name: `${lat}, ${lng}`,
          });
        }
      },
    });
    return <Marker position={airport.location.coordinates} />;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    if (!airport.name) errors.name = "Name is required.";
    if (!airport.location.coordinates || airport.location.coordinates.length !== 2)
      errors.location = "Please select a valid location.";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const airportData = {
      ...airport,
      location: {
        lat: airport.location.coordinates[0],
        lng: airport.location.coordinates[1],
        name: airport.location.name,
      },
    };

    setLoading(true);
    try {
      if (existingAirport?.id) {
        const airportRef = doc(db, "airports", existingAirport.id);
        await updateDoc(airportRef, airportData);
        onAirportAdded({ ...airportData, id: existingAirport.id });
      } else {
        const docRef = await addDoc(collection(db, "airports"), airportData);
        onAirportAdded({ ...airportData, id: docRef.id });
      }
      onClose();
    } catch (error) {
      console.error("Error saving airport:", error);
    }
    setLoading(false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        background: suguThemeColors.primary.navy,
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      <h2 style={{ color: suguThemeColors.primary.orange }}>
        {existingAirport ? "Edit Airport" : "Add New Airport"}
      </h2>

      <label style={{ display: "block", marginBottom: "10px", color: suguThemeColors.neutral.white }}>
        Name:
        <input
          type="text"
          value={airport.name}
          onChange={(e) =>
            setAirport((prev) => ({
              ...prev,
              name: e.target.value,
            }))
          }
          style={{
            border: `2px solid ${suguThemeColors.secondary.lightNavy}`,
            padding: "10px",
            width: "100%",
            color: suguThemeColors.primary.navy,
            backgroundColor: suguThemeColors.neutral.white,
          }}
        />
        {formErrors.name && <span style={{ color: suguThemeColors.primary.orange }}>{formErrors.name}</span>}
      </label>

      <label style={{ display: "block", marginBottom: "10px",  }}>
        <div style={{color: suguThemeColors.neutral.white}}>Location:</div>
        <div style={{color: suguThemeColors.neutral.white}}>{airport.location.name}</div>
        <MapContainer
          center={airport.location.coordinates}
          zoom={10}
          style={{ height: "300px", borderRadius: "10px", marginBottom: "10px" }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <LocationSelector />
          <SearchControl onLocationSelect={handleLocationSelect} />
        </MapContainer>
        {formErrors.location && (
          <span style={{ color: suguThemeColors.primary.orange }}>{formErrors.location}</span>
        )}
      </label>

      <label style={{ display: "block", marginBottom: "10px", color: suguThemeColors.neutral.white }}>
        Terminals (comma-separated):
        <input
          type="text"
          value={airport.terminals.join(", ")}
          onChange={(e) =>
            setAirport((prev) => ({
              ...prev,
              terminals: e.target.value.split(",").map((t) => t.trim()),
            }))
          }
          style={{
            border: `2px solid ${suguThemeColors.secondary.lightNavy}`,
            padding: "10px",
            width: "100%",
            color: suguThemeColors.primary.navy,
            backgroundColor: suguThemeColors.neutral.white,
          }}
        />
      </label>

      <button
        type="submit"
        disabled={loading}
        style={{
          background: suguThemeColors.primary.orange,
          color: suguThemeColors.neutral.white,
          padding: "10px 20px",
          borderRadius: "5px",
          border: "none",
        }}
      >
        {loading ? "Saving..." : existingAirport ? "Update Airport" : "Add Airport"}
      </button>
    </form>
  );
};

export default AddAirport;
