import React, { useState, useEffect } from "react";
import { Search, Edit2, Eye, ChevronUp, ChevronDown, X } from "lucide-react";
import MultiStepParkingForm from "../components/parkingForm";
import suguThemeColors from "../theme/suguThemeColors";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../services/firebase";

const CustomDialog = ({ open, onClose, children }) => {
  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose} />
      <div className="relative bg-white rounded-lg shadow-xl max-w-3xl w-full mx-4 max-h-[90vh] overflow-y-auto">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 p-2 rounded-full hover:bg-subtle-navy-100"
        >
          <X className="w-5 h-5" />
        </button>
        <div className="p-6">{children}</div>
      </div>
    </div>
  );
};

const CustomInput = ({ className, ...props }) => (
  <input
    className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-orange focus:border-transparent ${className}`}
    style={{
      borderColor: suguThemeColors.table.border,
      backgroundColor: suguThemeColors.neutral.white,
    }}
    {...props}
  />
);

const ParkingSlots = () => {
  const [isAddSlotClicked, setIsSlotClicked] = useState(false);
  const [parkingSlots, setParkingSlots] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [showDetailDialog, setShowDetailDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [airports, setAirports] = useState([]);

  const navigate = useNavigate();

  // Fetch data from Firebase - implement your actual fetch logic here

  useEffect(() => {
    const fetchParkingSlots = async () => {
      try {
        const parkingSlotsCollection = collection(db, "parkingSlots");
        const querySnapshot = await getDocs(parkingSlotsCollection);
        const slots = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(slots, "ALLLLLl ADATA");

        setParkingSlots(slots);
      } catch (err) {
        console.error("Error fetching parking slots:", err);
        setError("Failed to load parking slots. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    const fetchAirports = async () => {
      const data = await getDocs(collection(db, "airports"));
      setAirports(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    fetchAirports();
    fetchParkingSlots();
  }, []);

  const buttonStyles = {
    primary: {
      background: suguThemeColors.primary.orange,
      color: suguThemeColors.neutral.white,
      padding: "12px 24px",
      borderRadius: "6px",
      border: "none",
      cursor: "pointer",
      fontWeight: "600",
      display: "flex",
      alignItems: "center",
      gap: "8px",
      hover: {
        background: suguThemeColors.secondary.darkOrange,
      },
    },
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const getSortedData = () => {
    const filteredData = parkingSlots.filter(
      (slot) =>
        slot.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        // slot.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
        slot.type.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (!sortConfig.key) return filteredData;

    return [...filteredData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const SortIcon = ({ column }) => {
    if (sortConfig.key !== column) {
      return <ChevronUp className="w-4 h-4 text-gray-400" />;
    }
    return sortConfig.direction === "asc" ? (
      <ChevronUp className="w-4 h-4 text-primary" />
    ) : (
      <ChevronDown className="w-4 h-4 text-primary" />
    );
  };

  const TableHeader = ({ children, onClick, sortable = true }) => (
    <th
      className="border-b p-4 text-left font-semibold"
      onClick={sortable ? onClick : undefined}
      style={{
        cursor: sortable ? "pointer" : "default",
        backgroundColor: suguThemeColors.table.header,
        borderColor: suguThemeColors.table.border,
        color: suguThemeColors.primary.navy,
      }}
    >
      <div className="flex items-center gap-2">
        {children}
        {sortable && <SortIcon column={children.toLowerCase()} />}
      </div>
    </th>
  );

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1
          className="text-2xl font-bold"
          style={{ color: suguThemeColors.primary.navy }}
        >
          Manage Parking Slots
        </h1>
        <button
          style={{
            ...buttonStyles.primary,
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
          onMouseOver={(e) =>
            (e.target.style.background = buttonStyles.primary.hover.background)
          }
          onMouseOut={(e) =>
            (e.target.style.background = buttonStyles.primary.background)
          }
          onClick={() => setIsSlotClicked(!isAddSlotClicked)}
        >
          {isAddSlotClicked ? "Close" : "Add New Airport"}
        </button>
      </div>

      {isAddSlotClicked ? (
        <MultiStepParkingForm />
      ) : (
        <>
          <div className="mb-4 relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <CustomInput
              type="text"
              placeholder="Search by name, location, or type..."
              className="pl-10"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div
            className="overflow-x-auto rounded-lg border"
            style={{ borderColor: suguThemeColors.table.border }}
          >
            <table className="w-full">
              <thead>
                <tr>
                  <TableHeader onClick={() => handleSort("name")}>
                    Name
                  </TableHeader>
                  <TableHeader onClick={() => handleSort("location")}>
                    Location
                  </TableHeader>
                  <TableHeader onClick={() => handleSort("type")}>
                    Type
                  </TableHeader>
                  <TableHeader onClick={() => handleSort("capacity")}>
                    Capacity
                  </TableHeader>
                  <TableHeader onClick={() => handleSort("pricing")}>
                    Price
                  </TableHeader>
                  <TableHeader sortable={false}>Terminals</TableHeader>
                  <TableHeader sortable={false}>Actions</TableHeader>
                </tr>
              </thead>
              <tbody>
                {getSortedData().map((slot, index) => {
                  console.log(slot, "opoppppeep");
                  return (
                    <tr
                      key={slot.id}
                      className="hover:bg-subtle-navy-100 transition-colors"
                      style={{
                        backgroundColor:
                          index % 2 === 0
                            ? suguThemeColors.table.rowEven
                            : suguThemeColors.table.rowOdd,
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          suguThemeColors.table.rowHover)
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          index % 2 === 0
                            ? suguThemeColors.table.rowEven
                            : suguThemeColors.table.rowOdd)
                      }
                    >
                      {/* {airport.location.name ||
                      `${airport.location?.lat}, ${airport.location?.lng}`} */}
                      <td
                        className="p-4 border-b"
                        style={{ borderColor: suguThemeColors.table.border }}
                      >
                        {slot.name}
                      </td>
                      <td
                        className="p-4 border-b"
                        style={{ borderColor: suguThemeColors.table.border }}
                      >
                        {(() => {
                          const airport = airports.find(
                            (i) => i.id === slot.airport_id
                          );
                          return (
                            airport?.location?.name ||
                            `${airport?.location?.lat}, ${airport?.location?.lng}`
                          );
                        })()}
                      </td>
                      <td
                        className="p-4 border-b"
                        style={{ borderColor: suguThemeColors.table.border }}
                      >
                        {slot.type}
                      </td>
                      <td
                        className="p-4 border-b"
                        style={{ borderColor: suguThemeColors.table.border }}
                      >
                        {slot.capacity}
                      </td>
                      <td
                        className="p-4 border-b"
                        style={{ borderColor: suguThemeColors.table.border }}
                      >
                        £{slot.pricing}
                      </td>
                      <td
                        className="p-4 border-b"
                        style={{ borderColor: suguThemeColors.table.border }}
                      >
                        {
                          airports.filter((i) => i.id == slot.airport_id)[0]
                            .terminals
                        }
                      </td>
                      <td
                        className="p-4 border-b"
                        style={{ borderColor: suguThemeColors.table.border }}
                      >
                        <div className="flex gap-2">
                          <button
                            className="p-2 rounded-md hover:bg-subtle-navy-100"
                            onClick={() => {
                              // setSelectedSlot(slot);
                              // setShowDetailDialog(true);
                              navigate(`/parking-slot-edit/${slot.id}`);
                            }}
                          >
                            <Eye
                              className="w-5 h-5"
                              style={{ color: suguThemeColors.primary.navy }}
                            />
                          </button>
                          {/* <button
                          className="p-2 rounded-md hover:bg-subtle-navy-100"
                          onClick={() => {console.log("")}}
                        >
                          <Edit2 className="w-5 h-5" style={{ color: suguThemeColors.primary.navy }} />
                        </button> */}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default ParkingSlots;
