import React from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { 
  LayoutDashboard, 
  Plane, 
  SquareParking, 
  Users, 
  BookOpen, 
  LogOut ,
  TicketPercent
} from "lucide-react";
import logo from "../assets/SUGU PARK & FLY LOGO.jpg";

// Sugu Theme Colors
const suguThemeColors = {
  primary: {
    navy: "#0A2A3B",
    orange: "#F49B23",
  },
  secondary: {
    lightNavy: "#1C4C6B",
    darkNavy: "#051824",
    lightOrange: "#FFB347",
    darkOrange: "#D27D0A",
  },
  neutral: {
    grey: {
      50: "#F9FAFB",
      100: "#F2F4F7",
      200: "#EAECF0",
      300: "#D0D5DD",
      400: "#98A2B3",
      500: "#667085",
    },
  },
};

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get the current path
  const isAuthenticated = JSON.parse(localStorage.getItem('adminUser'));

  // Navigation items with icons
  const navItems = [
    {
      label: "Dashboard",
      path: "/dashboard",
      icon: <LayoutDashboard size={20} className="mr-3" />,
    },
    {
      label: "Manage Airports",
      path: "/airports",
      icon: <Plane size={20} className="mr-3" />,
    },
    {
      label: "Manage Parking Slots",
      path: "/parking-slots",
      icon: <SquareParking size={20} className="mr-3" />,
    },
    {
      label: "Manage Users",
      path: "/users",
      icon: <Users size={20} className="mr-3" />,
    },
    {
      label: "Manage Bookings",
      path: "/bookings",
      icon: <BookOpen size={20} className="mr-3" />,
    },
    {
        label: "Manage Coupons",
        path: "/coupon",
        icon: <TicketPercent size={20} className="mr-3" />,
      },
  ];
console.log(isAuthenticated.isSuperAdmin,"oiaioaoiaioa")
  const filteredNavItems = isAuthenticated.isSuperAdmin
    ? navItems
    : navItems.filter(
        (item) =>
          item.label !== "Manage Airports" &&
          item.label !== "Manage Parking Slots" &&
          item.label !== "Manage Users"
      );

  const handleLogout = () => {
    // Clear all authentication data
    localStorage.removeItem("adminUser");

    // Navigate to login page and replace the history
    navigate("/", { replace: true });
  };

  return (
    <div
      className="flex min-h-screen"
      style={{ backgroundColor: suguThemeColors.neutral.grey[50] }}
    >
      {/* Sidebar */}
      <aside
        className="fixed left-0 top-0 bottom-0 w-64 p-5 shadow-lg z-50 overflow-y-auto"
        style={{
          backgroundColor: suguThemeColors.primary.navy,
          color: suguThemeColors.neutral.grey[50],
        }}
      >
        {/* Logo and Company Name */}
        <img
          src={logo}
          alt="Sugu Park & Fly Logo"
          className="max-w-full max-h-full object-contain"
          style={{ borderRadius: 8, marginBottom: 20 }}
        />

        {/* Navigation Menu */}
        <nav>
          <ul>
            {filteredNavItems.map((item) => {
              const isActive = location.pathname === item.path;
              return (
                <li key={item.path} className="mb-3">
                  <Link
                    to={item.path}
                    className="flex items-center p-2 rounded-md transition-all duration-300"
                    style={{
                      backgroundColor: isActive
                        ? suguThemeColors.secondary.lightNavy
                        : "transparent",
                      color: isActive
                        ? suguThemeColors.primary.orange
                        : suguThemeColors.neutral.grey[50],
                    }}
                    onMouseEnter={(e) => {
                      if (!isActive) {
                        e.currentTarget.style.backgroundColor =
                          suguThemeColors.secondary.lightNavy;
                        e.currentTarget.style.color =
                          suguThemeColors.primary.orange;
                      }
                    }}
                    onMouseLeave={(e) => {
                      if (!isActive) {
                        e.currentTarget.style.backgroundColor = "transparent";
                        e.currentTarget.style.color =
                          suguThemeColors.neutral.grey[50];
                      }
                    }}
                  >
                    {item.icon}
                    {item.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>

        {/* Logout Button */}
        <div className="absolute bottom-5 left-0 right-0 px-5">
          <button
            onClick={handleLogout}
            className="w-full flex items-center justify-center p-2 rounded-md transition-all duration-300"
            style={{
              backgroundColor: suguThemeColors.secondary.lightOrange,
              color: suguThemeColors.primary.navy,
              ":hover": {
                backgroundColor: suguThemeColors.primary.orange,
                color: "white",
              },
            }}
          >
            <LogOut size={20} className="mr-3" />
            Logout
          </button>
        </div>
      </aside>

      {/* Main Content Area */}
      <main
        className="ml-64 flex-grow p-5"
        style={{ backgroundColor: suguThemeColors.neutral.grey[50] }}
      >
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
