import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../services/firebase";
import {
  ArrowUpDown,
  Search,
  X,
  Calendar,
  Clock,
  User,
  Plane,
  Car,
  CreditCard,
} from "lucide-react";

// Helper Components
const StatusBadge = ({ status, large = false }) => {
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "booked":
        return {
          bg: suguThemeColors.status.success.light,
          text: suguThemeColors.status.success.dark,
        };
      case "cancelled":
        return {
          bg: suguThemeColors.status.error.light,
          text: suguThemeColors.status.error.dark,
        };
      case "pending":
        return {
          bg: suguThemeColors.status.warning.light,
          text: suguThemeColors.status.warning.dark,
        };
      default:
        return {
          bg: suguThemeColors.status.info.light,
          text: suguThemeColors.status.info.dark,
        };
    }
  };

  const colors = getStatusColor(status);

  return (
    <span
      className={`
        inline-flex items-center rounded-full
        ${large ? "px-4 py-2" : "px-3 py-1"}
        font-medium text-sm
      `}
      style={{
        backgroundColor: colors.bg,
        color: colors.text,
      }}
    >
      {status}
    </span>
  );
};

const Section = ({ icon: Icon, title, content }) => {
  return (
    <div className="space-y-3">
      <div className="flex items-center gap-2 text-primary-navy">
        <Icon className="h-5 w-5" />
        <h3 className="font-medium">{title}</h3>
      </div>
      <div className="ml-7 space-y-2">
        {content.map(([label, value]) => (
          <div key={label} className="grid grid-cols-2 gap-2">
            <span className="text-neutral-grey-500">{label}:</span>
            <span className="text-neutral-grey-900">{value || "-"}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const ErrorDisplay = ({ message }) => (
  <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-700">
    <p>{message}</p>
  </div>
);

const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-64">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-navy"></div>
  </div>
);

// Helper functions for data fetching
const fetchDataInChunks = async (collection, ids, chunkSize = 10) => {
  const chunks = [];
  for (let i = 0; i < ids.length; i += chunkSize) {
    chunks.push(ids.slice(i, i + chunkSize));
  }

  const results = await Promise.all(
    chunks.map((chunk) => getDocs(query(collection, where("id", "in", chunk))))
  );

  return results
    .flatMap((snapshot) => snapshot.docs)
    .map((doc) => ({ id: doc.id, ...doc.data() }));
};

// BookingsTable Component
const BookingsTable = ({
  data,
  sortConfig,
  onSort,
  searchTerm,
  onSearchChange,
  onViewDetail,
  airports,
}) => {
  const columns = [
    { key: "id", label: "Booking ID" },
    { key: "user", label: "Customer" },
    { key: "airport", label: "Airport" },
    { key: "Drop_off_date", label: "Drop Off" },
    { key: "Pickup_date", label: "Pick Up" },
    { key: "status", label: "Status" },
    { key: "total_price", label: "Total Price" },
    { key: "actions", label: "actions" },
  ];
  return (
    <div className="bg-white rounded-lg shadow-sm border border-subtle-navy-200">
      <div className="p-6 border-b border-subtle-navy-200">
        <div className="flex flex-row items-center justify-between">
          <h2 className="text-xl font-bold text-primary-navy">Bookings</h2>
          <div className="relative w-64">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-neutral-grey-400" />
            <input
              type="text"
              placeholder="Search bookings..."
              className="w-full pl-8 pr-4 py-2 border border-subtle-navy-300 rounded-md"
              value={searchTerm}
              onChange={(e) => onSearchChange(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr style={{ backgroundColor: suguThemeColors.table.header }}>
              {columns.map((column) => (
                <th
                  key={column.key}
                  className="p-4 text-left font-medium text-neutral-grey-500 cursor-pointer hover:bg-subtle-navy-100"
                  onClick={() => column.key !== "actions" && onSort(column.key)}
                >
                  <div className="flex items-center gap-2">
                    {column.label}
                    {column.key !== "actions" && (
                      <ArrowUpDown className="h-4 w-4" />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((booking, index) => (
              <tr
                key={booking.id}
                className={`
                  border-b border-subtle-navy-200
                  ${index % 2 === 0 ? "bg-white" : "bg-subtle-navy-50"}
                  hover:bg-subtle-navy-100
                `}
              >
                <td className="p-4 font-medium">#{booking.id}</td>
                <td className="p-4">{booking.user?.name || "Unknown"}</td>
                <td className="p-4">
                  {airports.find((airport) => airport.id === booking.Airport_id)
                    ?.name || "Unknown"}
                </td>
                <td className="p-4">
                  {booking.Drop_off_date
                    ? booking.Drop_off_date.toDate().toLocaleDateString()
                    : "N/A"}
                  <br />
                  <span className="text-sm text-neutral-grey-400">
                    {booking.Drop_off_date
                      ? booking.Drop_off_date.toDate().toLocaleTimeString()
                      : "N/A"}
                  </span>
                </td>
                <td className="p-4">
                  {booking.Pickup_date
                    ? booking.Pickup_date.toDate().toLocaleDateString()
                    : "N/A"}
                  <br />
                  <span className="text-sm text-neutral-grey-400">
                    {booking.Pickup_date
                      ? booking.Pickup_date.toDate().toLocaleTimeString()
                      : "N/A"}
                  </span>
                </td>
                <td className="p-4">
                  <StatusBadge status={booking.status} />
                </td>
                <td className="p-4">
                  ${booking.total_price.toFixed(2)}
                  {booking.payment && (
                    <div className="text-sm text-neutral-grey-400">
                      {booking.payment.payment_method}
                    </div>
                  )}
                </td>
                <td className="p-4">
                  <button
                    onClick={() => onViewDetail(booking)}
                    className="px-3 py-1 text-sm font-medium text-primary-navy hover:bg-subtle-navy-100 rounded"
                  >
                    View Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// BookingDetailView Component
const BookingDetailView = ({ booking, onClose , airports }) => {
  const matchedAirport = airports.find(
    (airport) => airport.id === booking.Airport_id
  );
  return (
    <div className="bg-white rounded-lg shadow-sm border border-subtle-navy-200">
      <div className="p-6 border-b border-subtle-navy-200 flex justify-between items-center">
        <h2 className="text-xl font-bold text-primary-navy">
          Booking Details #{booking.id}
        </h2>
        <button
          onClick={onClose}
          className="p-2 hover:bg-subtle-navy-100 rounded-full"
        >
          <X className="h-5 w-5" />
        </button>
      </div>

      <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-6">
          <Section
            icon={User}
            title="Customer Information"
            content={[
              ["Name", booking.user?.name],
              ["Email", booking.user?.email],
              ["Phone", booking.user?.phone_number],
            ]}
          />

          <Section
            icon={Plane}
            title="Airport Details"
            content={[
              ["Airport", matchedAirport?.name || "Unknown"],
              ["Location", matchedAirport?.location || "Unknown"],
            ]}
          />

          <Section
            icon={Car}
            title="Parking Details"
            content={[
              ["Slot Type", booking.parkingSlot?.type],
              ["Slot Name", booking.parkingSlot?.name],
              // [
              //   "Features",
              //   Object.entries(booking.parkingSlot?.features || {})
              //     .map(([key, value]) => `${key}: ${value}`)
              //     .join(", "),
              // ],
            ]}
          />
        </div>

        <div className="space-y-6">
          <Section
            icon={Calendar}
            title="Booking Schedule"
            content={[
              [
                "Drop Off Date",
                booking.Drop_off_date?.toDate().toLocaleDateString() || "N/A",
              ],
              [
                "Drop Off Time",
                booking.Drop_off_date?.toDate().toLocaleTimeString() || "N/A",
              ],
              [
                "Pickup Date",
                booking.Pickup_date?.toDate().toLocaleDateString() || "N/A",
              ],
              [
                "Pickup Time",
                booking.Pickup_date?.toDate().toLocaleTimeString() || "N/A",
              ],
            ]}
          />

          <Section
            icon={CreditCard}
            title="Payment Information"
            content={[
              ["Total Price", `$${booking.total_price.toFixed(2)}`],
              ["Payment Method", booking.payment?.payment_method],
              ["Payment Status", booking.payment?.status],
              [
                "Transaction Date",
                new Date(booking.payment?.created_at).toLocaleDateString(),
              ],
            ]}
          />

        </div>
      </div>
          <div style={{flexDirection:'row',marginLeft:'42%',marginBottom:10}}>
            <StatusBadge status={booking.status} large />
          </div>
    </div>
  );
};

// Main BookingsPage Component
const BookingsPage = () => {
  const [bookings, setBookings] = useState([]);
  const [enrichedBookings, setEnrichedBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: "created_at",
    direction: "desc",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [airports, setAirports] = useState([]);

  const fetchAirports = async () => {
    const data = await getDocs(collection(db, "airports"));
    setAirports(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true);
        setError(null);

        const bookingsSnapshot = await getDocs(collection(db, "bookings"));
        const bookingsData = bookingsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (bookingsData.length === 0) {
          setLoading(false);
          return;
        }

        const paymentsSnapshot = await getDocs(collection(db, "payments"));
        const paymentsData = paymentsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const userIds = [...new Set(bookingsData.map((b) => b.User_id))];
        const airportIds = [...new Set(bookingsData.map((b) => b.Airport_id))];
        const slotIds = [
          ...new Set(bookingsData.map((b) => b.Parking_slot_id)),
        ];

        console.log("Airport IDs:", airportIds); // Debug the IDs

        const [usersData, airportsData, slotsData] = await Promise.all([
          fetchDataInChunks(collection(db, "users"), userIds),
          fetchDataInChunks(collection(db, "airports"), airportIds),
          fetchDataInChunks(collection(db, "parkingSlots"), slotIds),
        ]);

        console.log("Airports Data:", airportsData); // Check fetched airport data

        const enriched = bookingsData.map((booking) => ({
          ...booking,
          airport: airportsData.find((a) => a.id === booking.Airport_id),
          payment: paymentsData.find((p) => p.booking_id === booking.id),
          user: usersData.find((u) => u.id === booking.User_id),
          parkingSlot: slotsData.find((s) => s.id === booking.Parking_slot_id),
        }));

        setBookings(bookingsData);
        setEnrichedBookings(enriched);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load bookings. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchAirports();
    fetchAllData();
  }, []);

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const filteredAndSortedData = React.useMemo(() => {
    return enrichedBookings
      .filter((booking) => {
        const searchStr = searchTerm.toLowerCase();
        return (
          booking.id.toLowerCase().includes(searchStr) ||
          booking.user?.name?.toLowerCase().includes(searchStr) ||
          booking.airport?.name?.toLowerCase().includes(searchStr) ||
          booking.status.toLowerCase().includes(searchStr)
        );
      })
      .sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key.includes(".")) {
          const keys = sortConfig.key.split(".");
          aValue = keys.reduce((obj, key) => obj?.[key], a);
          bValue = keys.reduce((obj, key) => obj?.[key], b);
        }

        if (
          sortConfig.key.includes("date") ||
          sortConfig.key.includes("time")
        ) {
          aValue = new Date(aValue).getTime();
          bValue = new Date(bValue).getTime();
        }

        if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
  }, [enrichedBookings, searchTerm, sortConfig]);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <ErrorDisplay message={error} />
      ) : selectedBooking ? (
        <BookingDetailView
          booking={selectedBooking}
          onClose={() => setSelectedBooking(null)}
          airports={airports}
        />
      ) : (
        <BookingsTable
          data={filteredAndSortedData}
          sortConfig={sortConfig}
          onSort={handleSort}
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
          onViewDetail={setSelectedBooking}
          airports={airports}
        />
      )}
    </div>
  );
};

// Theme colors object (add at the top of the file)
const suguThemeColors = {
  table: {
    header: "#F8FAFC",
  },
  status: {
    success: {
      light: "#DCFCE7",
      dark: "#166534",
    },
    error: {
      light: "#FEE2E2",
      dark: "#991B1B",
    },
    warning: {
      light: "#FEF9C3",
      dark: "#854D0E",
    },
    info: {
      light: "#DBEAFE",
      dark: "#1E40AF",
    },
  },
};

export default BookingsPage;
