import React, { useState, useEffect } from "react";
import { Edit, Search, SortAsc, SortDesc, Trash2 } from "lucide-react";
import {
  collection,
  getDocs,
  Timestamp,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../services/firebase";
// Sugu Theme Colors
const suguThemeColors = {
  primary: {
    navy: "#0A2A3B",
    orange: "#F49B23",
  },
  secondary: {
    lightNavy: "#1C4C6B",
    darkNavy: "#051824",
    lightOrange: "#FFB347",
    darkOrange: "#D27D0A",
  },
  table: {
    header: "#F5F7FA",
    rowEven: "#FFFFFF",
    rowOdd: "#F5F7FA",
    rowHover: "#E9EEF3",
    rowSelected: "#FEF7EC",
    border: "#D4E0E9",
  },
};

const UsersManagement = () => {
  // Sample initial users data
  const [users, setUsers] = useState([]);

  // Edit state
  const [editingUser, setEditingUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });

  // Sorting function
  const sortedUsers = React.useMemo(() => {
    let sortableUsers = [...users];
    if (searchTerm) {
      sortableUsers = sortableUsers.filter((user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    sortableUsers.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    return sortableUsers;
  }, [users, searchTerm, sortConfig]);

  // Handle sorting
  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  // Handle edit
  const handleEdit = (user) => {
    setEditingUser({ ...user });
  };

  // Handle save edit
  const handleSave = async () => {
    try {
      // Update Firestore
      const userDocRef = doc(db, "users", editingUser.id); // Reference to the specific user document
      await updateDoc(userDocRef, {
        ...editingUser,
        updated_at: Timestamp.now(), // Update timestamp
      });

      // Update local state
      setUsers((prev) =>
        prev.map((user) =>
          user.id === editingUser.id
            ? {
                ...editingUser,
                updated_at: Timestamp.now(),
              }
            : user
        )
      );
      setEditingUser(null); // Exit editing mode
    } catch (error) {
      console.error("Error saving user: ", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      // Delete from Firestore
      const userDocRef = doc(db, "users", id); // Reference to the specific user document
      await deleteDoc(userDocRef);

      // Update local state
      setUsers((prev) => prev.filter((user) => user.id !== id));
    } catch (error) {
      console.error("Error deleting user: ", error);
    }
  };

  const createUser = async (userData) => {
    try {
      const newUser = {
        ...userData,
        created_at: Timestamp.now(),
        updated_at: Timestamp.now(),
      };

      const docRef = await addDoc(collection(db, "users"), newUser);
      console.log("User created with ID: ", docRef.id);
    } catch (error) {
      console.error("Error creating user: ", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      const usersData = querySnapshot.docs.map((doc) => ({
        id: doc.id, // Include document ID if needed
        ...doc.data(),
      }));
      //   console.log(usersData,"its dadadadada")
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };
  const addBookingAndPayment = async () => {
    const bookingSample = {
        User_id: "O4CBmDjsfoB5pa1qFWGT",
        Airport_id: "Zm1nNZpt6uBCQnPqXYzL",
        Parking_slot_id: "6GgkhS4cbXY80zlY1Ljm",
        Drop_off_date: new Date("2023-11-20"),
        Drop_off_time: new Date("2023-11-20T10:00:00"),
        Pickup_date: new Date("2023-11-25"),
        Pickup_time: new Date("2023-11-25T15:00:00"),
        status: "Booked",
        total_price: 120.50,
        created_at: new Date(),
        updated_at: new Date(),
      };
      
      const paymentSample = {
        payment_method: "Credit Card",
        status: "Success",
        created_at: new Date(),
        updated_at: new Date(),
      };
      
  try {
    // Add the booking data to "bookings" collection
    const bookingRef = await addDoc(collection(db, "bookings"), {
      ...bookingSample,
      Drop_off_date: Timestamp.now(),
      Drop_off_time: Timestamp.now(),
      Pickup_date: Timestamp.now(),
      Pickup_time: Timestamp.now(),
      created_at: Timestamp.now(),
      updated_at: Timestamp.now(),
    });

    console.log("Booking added with ID: ", bookingRef.id);

    // Add the payment data to "payments" collection
    const paymentRef = await addDoc(collection(db, "payments"), {
      ...paymentSample,
      booking_id: bookingRef.id, // Reference to the booking
      created_at: Timestamp.now(),
      updated_at: Timestamp.now(),
    });

    console.log("Payment added with ID: ", paymentRef.id);
  } catch (error) {
    console.error("Error adding data: ", error);
  }
};

  useEffect(() => {
    fetchUsers();
    // addBookingAndPayment()
    // const newUser = {
    //     name: "Sreedhar",
    //     email: "sreedoip@gmail.com",
    //     phone_number: 22200020,
    //   };

    //   createUser(newUser);
  }, []);

  // Define the order of columns
  const columnOrder = [
    "name",
    "email",
    "phone_number",
    "created_at",
    "updated_at",
    "password"
  ];

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{ backgroundColor: suguThemeColors.table.rowEven }}
    >
      <div className="container mx-auto">
        <h1
          className="text-3xl font-bold mb-6"
          style={{ color: suguThemeColors.primary.navy }}
        >
          User Management
        </h1>

        {/* Search and Sort Controls */}
        <div className="flex mb-4 space-x-4">
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder="Search by name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-2 border rounded"
              style={{
                borderColor: suguThemeColors.table.border,
                backgroundColor: suguThemeColors.table.rowEven,
              }}
            />
            <Search
              className="absolute right-3 top-3 text-gray-400"
              size={20}
            />
          </div>
        </div>

        {/* Users Table */}
        <div className="overflow-x-auto">
          <table
            className="w-full border-collapse"
            style={{
              borderColor: suguThemeColors.table.border,
              backgroundColor: suguThemeColors.table.rowEven,
            }}
          >
            <thead>
  <tr style={{ backgroundColor: suguThemeColors.table.header }}>
    {[
      "name",
      "email",
      "phone_number",
      "created_at",
      "updated_at",
      "password"
    ].map((key) => (
      <th
        key={key}
        onClick={() => handleSort(key)}
        className="p-3 text-left cursor-pointer hover:bg-gray-100"
      >
        <div className="flex items-center">
          {key.replace("_", " ")}
          {sortConfig.key === key && (
            sortConfig.direction === "asc" ? (
              <SortAsc className="ml-2" size={16} />
            ) : (
              <SortDesc className="ml-2" size={16} />
            )
          )}
        </div>
      </th>
    ))}
    <th className="p-3 text-left">Actions</th>
  </tr>
</thead>

<tbody>
  {sortedUsers.map((user, index) => (
    <tr
      key={user.id}
      style={{
        backgroundColor:
          index % 2 === 0
            ? suguThemeColors.table.rowEven
            : suguThemeColors.table.rowOdd,
      }}
      className="hover:bg-gray-100"
    >
      {editingUser && editingUser.id === user.id ? (
        // Editing Row
        <>
          {columnOrder.map((key) => {
            if (key === "created_at" || key === "updated_at") {
              return (
                <td key={key} className="p-3">
                  {user[key]
                    ? new Timestamp(
                        user[key].seconds,
                        user[key].nanoseconds
                      )
                        .toDate()
                        .toLocaleString()
                    : "N/A"}
                </td>
              );
            }
            return (
              <td key={key} className="p-3">
                <input
                  type="text"
                  value={editingUser[key]}
                  onChange={(e) =>
                    setEditingUser((prev) => ({
                      ...prev,
                      [key]: e.target.value,
                    }))
                  }
                  className="w-full p-1 border rounded"
                />
              </td>
            );
          })}
          <td className="p-3">
            <button
              onClick={handleSave}
              className="mr-2 p-1 bg-green-500 text-white rounded"
            >
              Save
            </button>
            <button
              onClick={() => setEditingUser(null)}
              className="p-1 bg-red-500 text-white rounded"
            >
              Cancel
            </button>
          </td>
        </>
      ) : (
        // Regular Row
        <>
          {columnOrder.map((key) => (
            <td key={key} className="p-3">
              {key === "created_at" || key === "updated_at"
                ? user[key]
                  ? new Timestamp(
                      user[key].seconds,
                      user[key].nanoseconds
                    )
                      .toDate()
                      .toLocaleString()
                  : "N/A"
                : key === "password"
                ? user[key] || "N/A" // Ensure password field renders
                : user[key]}
            </td>
          ))}
          <td className="p-3">
            <div className="flex space-x-2">
              <button
                onClick={() => handleEdit(user)}
                className="text-blue-500 hover:text-blue-700"
              >
                <Edit size={20} />
              </button>
              <button
                onClick={() => handleDelete(user.id)}
                className="text-red-500 hover:text-red-700"
              >
                <Trash2 size={20} />
              </button>
            </div>
          </td>
        </>
      )}
    </tr>
  ))}
</tbody>

          </table>
        </div>
      </div>
    </div>
  );
};

export default UsersManagement;
