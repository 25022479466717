// src/components/AuthProtection.jsx
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// Protected Route Component
export const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('adminUser');
  const location = useLocation();

  if (!isAuthenticated) {
    // Redirect to the login page if not authenticated
    return <Navigate to="/" replace />;
  }

  return children;
};

// Public Route Component (for login page)
export const PublicRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('adminUser');
  
  if (isAuthenticated) {
    // Redirect to dashboard if already authenticated
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};