import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../services/firebase'; // Ensure this path matches your Firebase config file location
import logo from '../assets/SUGU PARK & FLY LOGO.jpg'


const LoginScreen = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
  
    // Prevent browser back button
    useEffect(() => {
      window.history.pushState(null, '', window.location.pathname);
      window.addEventListener('popstate', function(event) {
        window.history.pushState(null, '', window.location.pathname);
      });
  
      return () => {
        window.removeEventListener('popstate', () => {});
      };
    }, []);
  
    const handleLogin = async (e) => {
      e.preventDefault();
      setError('');
      setIsLoading(true);
  
      try {
        if (!username || !password) {
          setError('Please enter both username and password');
          setIsLoading(false);
          return;
        }
  
        const adminUsersRef = collection(db, 'adminUsers');
        const q = query(
          adminUsersRef,
          where('name', '==', username),
          where('password', '==', password)
        );
  
        const querySnapshot = await getDocs(q);
  
        if (querySnapshot.empty) {
          setError('Invalid username or password');
          setIsLoading(false);
          return;
        }
  
        const adminUser = querySnapshot.docs[0].data();
        
        localStorage.setItem('adminUser', JSON.stringify({
          id: querySnapshot.docs[0].id,
          username: adminUser.username,
          isSuperAdmin: adminUser.isSuperAdmin
        }));
  
        // Navigate and replace history
        navigate('/dashboard', { replace: true });
      } catch (error) {
        console.error('Login error:', error);
        setError('An error occurred during login. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#F5F7FA]">
      <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
        <div className="space-y-6">
          {/* Logo placeholder */}
          <div style={{padding:20}} className="max-w-full max-h-full mx-auto flex items-center justify-center rounded-lg">
  {/* <span className="text-white text-xl">LOGO</span> */}
  <img src={logo} className="max-w-full max-h-full object-contain" />
</div>
          <h2 className="text-2xl font-bold text-center text-[#0A2A3B]">
            Welcome Back
          </h2>
        </div>
        
        <div className="mt-8">
          <form onSubmit={handleLogin} className="space-y-6">
            {error && (
              <div className="bg-[#FEE7E7] text-[#D92D20] p-3 rounded-md mb-4">
                {error}
              </div>
            )}
            
            <div className="space-y-2">
              <label 
                htmlFor="username" 
                className="block text-sm font-medium text-[#1C4C6B]"
              >
                Username
              </label>
              <input
                id="username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full px-3 py-2 border border-[#D4E0E9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#F49B23] focus:border-[#F49B23]"
                placeholder="Enter your username"
                disabled={isLoading}
              />
            </div>
            
            <div className="space-y-2">
              <label 
                htmlFor="password" 
                className="block text-sm font-medium text-[#1C4C6B]"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 border border-[#D4E0E9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#F49B23] focus:border-[#F49B23]"
                placeholder="Enter your password"
                disabled={isLoading}
              />
            </div>
            
            <button
              type="submit"
              className="w-full bg-[#F49B23] hover:bg-[#D27D0A] text-white py-2 px-4 rounded-md transition-colors duration-200 font-medium disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={isLoading}
            >
              {isLoading ? (
                <span className="flex items-center justify-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Signing in...
                </span>
              ) : (
                'Sign In'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;