import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  setDoc,
} from "firebase/firestore";
import { db } from "../services/firebase";

const schema = yup.object().shape({
  airport_id: yup.string().required("Airport is required"),
  name: yup.string().required("Parking slot name is required"),
  type: yup.string().required("Parking type is required"),
  capacity: yup
    .number()
    .typeError("Capacity must be a number")
    .positive("Capacity must be greater than zero")
    .integer("Capacity must be an integer")
    .required("Capacity is required"),
  pricing: yup
    .number()
    .typeError("Pricing must be a number")
    .positive("Pricing must be greater than zero")
    .required("Pricing is required"),
  features: yup.object().shape({
    overview: yup.object().shape({
      company: yup.string().required("Company name is required"),
      location: yup.string().required("Location is required"),
      type: yup.string(),
      price: yup
        .number()
        .typeError("Price must be a number")
        .positive("Price must be greater than zero"),
      address: yup.object().shape({
        name: yup.string().required("Address name is required"),
        street: yup.string().required("Street is required"),
        area: yup.string().required("Area is required"),
        city: yup.string().required("City is required"),
        postcode: yup.string().required("Postcode is required"),
      }),
      contactNumber: yup.string().required("Contact number is required"),
      cancellationCoverAvailable: yup.boolean().required(),
      directions: yup
        .string()
        .url("Must be a valid URL")
        .required("Directions URL is required"),
    }),
  }),
  overview: yup.object().shape({
    bookingConfirmation: yup
      .string()
      .required("Booking confirmation is required"),
    cancellationPolicy: yup
      .string()
      .required("Cancellation policy is required"),
    definitions: yup.string(),
    pricesAndPayments: yup
      .string()
      .required("Prices and payments information is required"),
    conditionsByRequest: yup.string(),
    exclusionsAndLimits: yup
      .string()
      .required("Exclusions and limits is required"),
    customerResponsibilities: yup
      .string()
      .required("Customer responsibilities is required"),
    ourResponsibilities: yup
      .string()
      .required("Our responsibilities is required"),
    specialAssistance: yup.string(),
    vehicleSecurity: yup
      .string()
      .required("Vehicle security information is required"),
    transportToAirport: yup.string(),
    checkInTime: yup.string().required("Check-in time is required"),
    general: yup.string(),
  }),
  dropoff_procedure: yup.object().shape({
    terminals: yup.array().of(
      yup.object().shape({
        terminal_name: yup.string().required("Terminal name is required"),
        car_park_address: yup.string().required("Car park address is required"),
        departure_procedure: yup
          .string()
          .required("Departure procedure is required"),
      })
    ),
    // .required("At least one terminal procedure is required"),
    departureProcedure: yup.string().required("Departure procedures required"),
    otherInfo: yup
      .string()
      .optional()
      .max(500, "Other info must not exceed 500 characters"),
    optionalInfo: yup
      .string()
      .optional()
      .max(500, "Optional info must not exceed 500 characters"),
  }),
  return_procedure: yup.object().shape({
    terminals: yup.array().of(
      yup.object().shape({
        terminal_name: yup.string().required("Terminal name is required"),
        car_park_address: yup.string().required("Car park address is required"),
        return_procedure: yup.string().required("Return procedure is required"),
      })
    ),
    // .required("At least one terminal procedure is required"),
    departureProcedure: yup.string().required("Return procedures required"),
    otherInfo: yup
      .string()
      .optional()
      .max(500, "Other info must not exceed 500 characters"),
    optionalInfo: yup
      .string()
      .optional()
      .max(500, "Optional info must not exceed 500 characters"),
  }),
});

// Step components
const BasicInfo = ({ register, errors, airports }) => (
  <div className="space-y-4">
    <div>
      <label className="block text-sm font-medium mb-1">Airport</label>
      <select {...register("airport_id")} className="w-full p-2 border rounded">
        <option value="" disabled>
          Select an airport
        </option>
        {airports.map((airport) => (
          <option key={airport.id} value={airport.id}>
            {airport.name}
          </option>
        ))}
      </select>
      <p className="text-red-500 text-sm">{errors.airport_id?.message}</p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">
        Parking Slot Name
      </label>
      <input {...register("name")} className="w-full p-2 border rounded" />
      <p className="text-red-500 text-sm">{errors.name?.message}</p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">Parking Type</label>
      {/* <input {...register("type")} className="w-full p-2 border rounded" /> */}
      <select {...register("type")} className="w-full p-2 border rounded">
        <option value="" disabled>
          Select parking type
        </option>
        {["Meet and Greet", "Park and Ride"].map((airport) => (
          <option key={airport} value={airport}>
            {airport}
          </option>
        ))}
      </select>
      <p className="text-red-500 text-sm">{errors.type?.message}</p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">Capacity</label>
      <input
        type="number"
        {...register("capacity")}
        className="w-full p-2 border rounded"
      />
      <p className="text-red-500 text-sm">{errors.capacity?.message}</p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">
        Pricing (per day/hour)
      </label>
      <input
        type="number"
        {...register("pricing")}
        className="w-full p-2 border rounded"
      />
      <p className="text-red-500 text-sm">{errors.pricing?.message}</p>
    </div>
  </div>
);

const CompanyInfo = ({ register, errors }) => (
  <div className="space-y-4">
    <div>
      <label className="block text-sm font-medium mb-1">Company</label>
      <input
        {...register("features.overview.company")}
        className="w-full p-2 border rounded"
      />
      <p className="text-red-500 text-sm">
        {errors.features?.overview?.company?.message}
      </p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">Location</label>
      <input
        {...register("features.overview.location")}
        className="w-full p-2 border rounded"
      />
      <p className="text-red-500 text-sm">
        {errors.features?.overview?.location?.message}
      </p>
    </div>

    {/* <div>
      <label className="block text-sm font-medium mb-1">Type</label>
      <input {...register("features.overview.type")} className="w-full p-2 border rounded" />
      <p className="text-red-500 text-sm">{errors.features?.overview?.type?.message}</p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">Price</label>
      <input type="number" {...register("features.overview.price")} className="w-full p-2 border rounded" />
      <p className="text-red-500 text-sm">{errors.features?.overview?.price?.message}</p>
    </div> */}

    <div>
      <label className="block text-sm font-medium mb-1">Contact Number</label>
      <input
        {...register("features.overview.contactNumber")}
        className="w-full p-2 border rounded"
      />
      <p className="text-red-500 text-sm">
        {errors.features?.overview?.contactNumber?.message}
      </p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">
        Cancellation Cover Available
      </label>
      <select
        {...register("features.overview.cancellationCoverAvailable")}
        className="w-full p-2 border rounded"
      >
        <option value={true}>Yes</option>
        <option value={false}>No</option>
      </select>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">Directions URL</label>
      <input
        type="url"
        {...register("features.overview.directions")}
        className="w-full p-2 border rounded"
      />
      <p className="text-red-500 text-sm">
        {errors.features?.overview?.directions?.message}
      </p>
    </div>
  </div>
);

const AddressInfo = ({ register, errors }) => (
  <div className="space-y-4">
    <div>
      <label className="block text-sm font-medium mb-1">Address</label>
      <input
        {...register("features.overview.address.name")}
        className="w-full p-2 border rounded"
      />
      <p className="text-red-500 text-sm">
        {errors.features?.overview?.address?.name?.message}
      </p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">Street</label>
      <input
        {...register("features.overview.address.street")}
        className="w-full p-2 border rounded"
      />
      <p className="text-red-500 text-sm">
        {errors.features?.overview?.address?.street?.message}
      </p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">Area</label>
      <input
        {...register("features.overview.address.area")}
        className="w-full p-2 border rounded"
      />
      <p className="text-red-500 text-sm">
        {errors.features?.overview?.address?.area?.message}
      </p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">City</label>
      <input
        {...register("features.overview.address.city")}
        className="w-full p-2 border rounded"
      />
      <p className="text-red-500 text-sm">
        {errors.features?.overview?.address?.city?.message}
      </p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">Postcode</label>
      <input
        {...register("features.overview.address.postcode")}
        className="w-full p-2 border rounded"
      />
      <p className="text-red-500 text-sm">
        {errors.features?.overview?.address?.postcode?.message}
      </p>
    </div>
  </div>
);

const OverviewStep = ({ register, errors }) => (
  <div className="space-y-4">
    <div>
      <label className="block text-sm font-medium mb-1">
        Booking Confirmation*
      </label>
      <textarea
        {...register("overview.bookingConfirmation")}
        className="w-full p-2 border rounded min-h-[100px]"
      />
      <p className="text-red-500 text-sm">
        {errors.overview?.bookingConfirmation?.message}
      </p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">
        Cancellation Policy*
      </label>
      <textarea
        {...register("overview.cancellationPolicy")}
        className="w-full p-2 border rounded min-h-[100px]"
      />
      <p className="text-red-500 text-sm">
        {errors.overview?.cancellationPolicy?.message}
      </p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">Definitions</label>
      <textarea
        {...register("overview.definitions")}
        className="w-full p-2 border rounded min-h-[100px]"
      />
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">
        Prices, Payments, and Cost Recovery*
      </label>
      <textarea
        {...register("overview.pricesAndPayments")}
        className="w-full p-2 border rounded min-h-[100px]"
      />
      <p className="text-red-500 text-sm">
        {errors.overview?.pricesAndPayments?.message}
      </p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">
        Conditions by Request
      </label>
      <textarea
        {...register("overview.conditionsByRequest")}
        className="w-full p-2 border rounded min-h-[100px]"
      />
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">
        Exclusion and Limits of Responsibility*
      </label>
      <textarea
        {...register("overview.exclusionsAndLimits")}
        className="w-full p-2 border rounded min-h-[100px]"
      />
      <p className="text-red-500 text-sm">
        {errors.overview?.exclusionsAndLimits?.message}
      </p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">
        Customer Responsibilities*
      </label>
      <textarea
        {...register("overview.customerResponsibilities")}
        className="w-full p-2 border rounded min-h-[100px]"
      />
      <p className="text-red-500 text-sm">
        {errors.overview?.customerResponsibilities?.message}
      </p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">
        Our Responsibilities*
      </label>
      <textarea
        {...register("overview.ourResponsibilities")}
        className="w-full p-2 border rounded min-h-[100px]"
      />
      <p className="text-red-500 text-sm">
        {errors.overview?.ourResponsibilities?.message}
      </p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">
        Special Assistance
      </label>
      <textarea
        {...register("overview.specialAssistance")}
        className="w-full p-2 border rounded min-h-[100px]"
      />
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">
        Vehicle Security*
      </label>
      <textarea
        {...register("overview.vehicleSecurity")}
        className="w-full p-2 border rounded min-h-[100px]"
      />
      <p className="text-red-500 text-sm">
        {errors.overview?.vehicleSecurity?.message}
      </p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">
        Transport to and from the Airport
      </label>
      <textarea
        {...register("overview.transportToAirport")}
        className="w-full p-2 border rounded min-h-[100px]"
      />
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">Check-in Time*</label>
      <textarea
        {...register("overview.checkInTime")}
        className="w-full p-2 border rounded min-h-[100px]"
      />
      <p className="text-red-500 text-sm">
        {errors.overview?.checkInTime?.message}
      </p>
    </div>

    <div>
      <label className="block text-sm font-medium mb-1">General</label>
      <textarea
        {...register("overview.general")}
        className="w-full p-2 border rounded min-h-[100px]"
      />
    </div>
  </div>
);

const DropoffProcedure = ({ register, errors, airports, watch }) => {
  const selectedAirport = watch("airport_id");
  // const airports = useContext(YourAirportContext); // Replace with your actual context

  const getTerminals = () => {
    console.log(airports, selectedAirport, "901091oooo90");
    const airport = airports.find((a) => a.id === selectedAirport);
    return airport?.terminals || [];
  };
  const terminals = getTerminals();
  return (
    <div className="space-y-6">
      <h3 className="text-lg font-semibold">Drop-off Procedures</h3>
      <div>
        <label className="block text-sm font-medium mb-1">
          Departure Procedure*
        </label>
        <textarea
          {...register("dropoff_procedure.departureProcedure")}
          className="w-full p-2 border rounded min-h-[100px]"
        />
        <p className="text-red-500 text-sm">
          {errors.dropoff_procedure?.departureProcedure?.message}
        </p>
      </div>
      {terminals.map((terminal, index) => (
        <div key={terminal} className="p-4 border rounded-lg space-y-4">
          <h4 className="font-medium">Terminal {terminal.trim()}</h4>

          {/* Hidden field for terminal name */}
          <input
            type="hidden"
            {...register(`dropoff_procedure.terminals.${index}.terminal_name`)}
            value={terminal.trim()}
          />

          <div>
            <label className="block text-sm font-medium mb-1">
              Car Park Address
            </label>
            <textarea
              {...register(
                `dropoff_procedure.terminals.${index}.car_park_address`
              )}
              className="w-full p-2 border rounded"
              rows={3}
            />
            <p className="text-red-500 text-sm">
              {
                errors.dropoff_procedure?.terminals?.[index]?.car_park_address
                  ?.message
              }
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">
              Departure Procedure
            </label>
            <textarea
              {...register(
                `dropoff_procedure.terminals.${index}.departure_procedure`
              )}
              className="w-full p-2 border rounded"
              rows={4}
            />
            <p className="text-red-500 text-sm">
              {
                errors.dropoff_procedure?.terminals?.[index]
                  ?.departure_procedure?.message
              }
            </p>
          </div>
        </div>
      ))}
      <div>
  <label className="block text-sm font-medium mb-1">Other Info</label>
  <textarea
    {...register("dropoff_procedure.otherInfo")}
    className="w-full p-2 border rounded min-h-[100px]"
    rows={4}
  />
  <p className="text-red-500 text-sm">
    {errors.dropoff_procedure?.otherInfo?.message}
  </p>
</div>

<div>
  <label className="block text-sm font-medium mb-1">Optional Info</label>
  <textarea
    {...register("dropoff_procedure.optionalInfo")}
    className="w-full p-2 border rounded min-h-[100px]"
    rows={4}
  />
  <p className="text-red-500 text-sm">
    {errors.dropoff_procedure?.optionalInfo?.message}
  </p>
</div>

    </div>
  );
};

// Return Procedure Component
const ReturnProcedure = ({ register, errors, airports, watch }) => {
  const selectedAirport = watch("airport_id");

  const getTerminals = () => {
    const airport = airports.find((a) => a.id === selectedAirport);
    return airport?.terminals || [];
  };

  const terminals = getTerminals();

  return (
    <div className="space-y-6">
      <h3 className="text-lg font-semibold">Return Procedures</h3>
      <div>
        <label className="block text-sm font-medium mb-1">
          Return Procedure*
        </label>
        <textarea
          {...register("return_procedure.departureProcedure")}
          className="w-full p-2 border rounded min-h-[100px]"
        />
        <p className="text-red-500 text-sm">
          {errors.return_procedure?.departureProcedure?.message}
        </p>
      </div>
      {terminals.map((terminal, index) => (
        <div key={terminal} className="p-4 border rounded-lg space-y-4">
          <h4 className="font-medium">Terminal {terminal.trim()}</h4>

          {/* Hidden field for terminal name */}
          <input
            type="hidden"
            {...register(`return_procedure.terminals.${index}.terminal_name`)}
            value={terminal.trim()}
          />

          <div>
            <label className="block text-sm font-medium mb-1">
              Car Park Address
            </label>
            <textarea
              {...register(
                `return_procedure.terminals.${index}.car_park_address`
              )}
              className="w-full p-2 border rounded"
              rows={3}
            />
            <p className="text-red-500 text-sm">
              {
                errors.return_procedure?.terminals?.[index]?.car_park_address
                  ?.message
              }
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">
              Return Procedure
            </label>
            <textarea
              {...register(
                `return_procedure.terminals.${index}.return_procedure`
              )}
              className="w-full p-2 border rounded"
              rows={4}
            />
            <p className="text-red-500 text-sm">
              {
                errors.return_procedure?.terminals?.[index]?.return_procedure
                  ?.message
              }
            </p>
          </div>
        </div>
      ))}

      {/* New Fields: Other Info and Optional Info */}
      <div>
        <label className="block text-sm font-medium mb-1">Other Info</label>
        <textarea
          {...register("return_procedure.otherInfo")}
          className="w-full p-2 border rounded min-h-[100px]"
          rows={4}
        />
        <p className="text-red-500 text-sm">
          {errors.return_procedure?.otherInfo?.message}
        </p>
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">Optional Info</label>
        <textarea
          {...register("return_procedure.optionalInfo")}
          className="w-full p-2 border rounded min-h-[100px]"
          rows={4}
        />
        <p className="text-red-500 text-sm">
          {errors.return_procedure?.optionalInfo?.message}
        </p>
      </div>
    </div>
  );
};


const MultiStepParkingForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [airports, setAirports] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    const fetchAirports = async () => {
      const airportsSnapshot = await getDocs(collection(db, "airports"));
      const airportsData = airportsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAirports(airportsData);
    };

    fetchAirports();
  }, []);
  useEffect(() => {
    const subscription = watch((data) => {
      schema
        .validate(data, { abortEarly: false })
        .then(() => console.log("Form is valid"))
        .catch((err) => console.log("Validation errors:", err.errors));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const steps = [
    {
      title: "Basic Information",
      component: (
        <BasicInfo register={register} errors={errors} airports={airports} />
      ),
    },
    {
      title: "Company Details",
      component: <CompanyInfo register={register} errors={errors} />,
    },
    {
      title: "Address Information",
      component: <AddressInfo register={register} errors={errors} />,
    },
    {
      title: "Overview",
      component: <OverviewStep register={register} errors={errors} />,
    },
    {
      title: "Drop-off Procedure",
      component: (
        <DropoffProcedure
          register={register}
          errors={errors}
          airports={airports}
          watch={watch}
        />
      ),
    },
    {
      title: "Return Procedure",
      component: (
        <ReturnProcedure
          register={register}
          errors={errors}
          airports={airports}
          watch={watch}
        />
      ),
    },
  ];

  const onSubmit = async (data) => {
    if (Object.keys(errors).length > 0) {
      alert(`${Object.keys(errors).length} mandatory fields are missing.`);
      return;
    }

    setLoading(true); // Set loading to true when submitting
    try {
      const newDocRef = doc(collection(db, "parkingSlots"));
      const parkingData = {
        id: newDocRef.id,
        ...data,
      };

      console.log("parking data", parkingData);
      await setDoc(newDocRef, parkingData);

      alert("Parking slot successfully added!");
      reset();
      setCurrentStep(0);
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Failed to submit data.");
    } finally {
      setLoading(false); // Reset loading state after submission attempt
    }
  };

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6">
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`flex items-center ${
                index === currentStep
                  ? "text-blue-600"
                  : index < currentStep
                  ? "text-green-600"
                  : "text-gray-400"
              }`}
            >
              <div
                className={`h-6  flex items-center justify-center  
                ${
                  index === currentStep
                    ? "border-blue-600 bg-blue-50"
                    : index < currentStep
                    ? "border-green-600 bg-green-50"
                    : "border-gray-300"
                }`}
              >
                {index + 1}
              </div>
              <div className="ml-2 text-sm font-medium">{step.title}</div>
              {index < steps.length - 1 && (
                <div className="w-12 h-1 mx-2 bg-gray-200"></div>
              )}
            </div>
          ))}
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        {steps[currentStep].component}

        <div className="flex justify-between mt-8">
          <button
            type="button"
            onClick={prevStep}
            disabled={currentStep === 0}
            className={`px-4 py-2 rounded-md ${
              currentStep === 0
                ? "bg-gray-200 cursor-not-allowed"
                : "bg-blue-600 text-white hover:bg-blue-700"
            }`}
          >
            Previous
          </button>

          {currentStep === steps.length - 1 ? (
            <button
              type="submit"
              disabled={loading || Object.keys(errors).length > 0}
              className={`px-4 py-2 rounded-md ${
                loading
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-green-600 text-white hover:bg-green-700"
              }`}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          ) : (
            <button
              type="button"
              onClick={nextStep}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Next
            </button>
          )}
        </div>
      </form>
      {currentStep === steps.length - 1 && (
        <div className="mt-4 text-sm text-red-600">
          {Object.keys(errors).length > 0
            ? `${
                Object.keys(errors).length
              } mandatory fields need to be filled.`
            : "All mandatory fields are filled."}
        </div>
      )}
    </div>
  );
};

export default MultiStepParkingForm;
