import React, { useState, useEffect } from "react";
import { db } from "../services/firebase";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  doc,
  setDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import {
  Activity,
  Users,
  Plane,
  Car,
  Calendar,
  TrendingUp,
  ArrowUpRight,
  ArrowDownRight,
} from "lucide-react";

const Dashboard = () => {
  const [stats, setStats] = useState({
    users: 0,
    airports: 0,
    parkingSlots: 0,
    bookings: 0,
    recentBookings: [],
    loading: true,
    bookingCharges: null,
  });

  const [isBookingChargesModalOpen, setBookingChargesModalOpen] =
    useState(false);
  const [isCouponModalOpen, setCouponModalOpen] = useState(false);
  const [bookingChargeInput, setBookingChargeInput] = useState("");
  const [couponDetails, setCouponDetails] = useState({
    code: "",
    validity: "",
    discount: "",
  });
  const [validationErrors, setValidationErrors] = React.useState({
    code: "",
    validity: "",
    discount: "",
  });
  const [chargesValidationError, setChargesValidationError] =
    React.useState("");

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const usersSnap = await getDocs(collection(db, "users"));
        const airportsSnap = await getDocs(collection(db, "airports"));
        const parkingSlotsSnap = await getDocs(collection(db, "parkingSlots"));
        const bookingsSnap = await getDocs(collection(db, "bookings"));

        // Fetch recent bookings
        const recentBookingsQuery = query(
          collection(db, "bookings"),
          orderBy("createdAt", "desc"),
          limit(5)
        );
        const recentBookingsSnap = await getDocs(recentBookingsQuery);
        const recentBookings = recentBookingsSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch booking charges
        const bookingChargesSnap = await getDocs(
          collection(db, "bookingCharges")
        );
        const bookingCharges =
          bookingChargesSnap.docs.length > 0
            ? bookingChargesSnap.docs[0].data().price
            : null;

        setStats({
          users: usersSnap.size,
          airports: airportsSnap.size,
          parkingSlots: parkingSlotsSnap.size,
          bookings: bookingsSnap.size,
          recentBookings,
          loading: false,
          bookingCharges,
        });
      } catch (error) {
        console.error("Error fetching stats:", error);
        setStats((prev) => ({ ...prev, loading: false }));
      }
    };
    fetchStats();
  }, []);

  const handleUpdateBookingCharges = async () => {
    try {
      const chargesRef = collection(db, "bookingCharges");
      const chargesSnap = await getDocs(chargesRef);
      if (!bookingChargeInput || bookingChargeInput <= 0) {
        setChargesValidationError("Booking charges must be a positive number.");
        return;
      }

      // Proceed with updating booking charges
      setChargesValidationError("");
      setBookingChargesModalOpen(false);

      if (chargesSnap.docs.length > 0) {
        const chargesDoc = chargesSnap.docs[0];
        await updateDoc(doc(db, "bookingCharges", chargesDoc.id), {
          price: parseFloat(bookingChargeInput),
        });
      } else {
        await addDoc(chargesRef, { price: parseFloat(bookingChargeInput) });
      }
      alert("Booking charges updated successfully!");
      setStats((prev) => ({
        ...prev,
        bookingCharges: parseFloat(bookingChargeInput),
      }));
      setBookingChargesModalOpen(false);
    } catch (error) {
      console.error("Error updating booking charges:", error);
    }
  };

  const handleAddCoupon = async () => {
    try {
      const errors = {};

      if (!couponDetails.code.trim()) {
        errors.code = "Coupon code is required.";
      }

      if (!couponDetails.validity.trim()) {
        errors.validity = "Validity date is required.";
      }

      if (
        !couponDetails.discount ||
        couponDetails.discount <= 0 ||
        couponDetails.discount > 100
      ) {
        errors.discount = "Discount must be between 1 and 100.";
      }

      if (Object.keys(errors).length) {
        setValidationErrors(errors);
        return;
      }

      // Proceed with adding the coupon
      setValidationErrors({});
      await addDoc(collection(db, "coupons"), {
        code: couponDetails.code,
        validity: couponDetails.validity,
        discount: parseInt(couponDetails.discount),
        createdAt: new Date(),
      });
      alert("Coupon added successfully!");
      setCouponModalOpen(false);
      setCouponDetails({ code: "", validity: "", discount: "" });
    } catch (error) {
      console.error("Error adding coupon:", error);
    }
  };

  const StatCard = ({ title, value, icon: Icon, trend, color }) => (
    <div className="bg-white p-6 rounded-lg shadow-md border border-[#D4E0E9]">
      <div className="flex items-center justify-between mb-4">
        <div className={`p-3 rounded-full ${color}`}>
          <Icon className="text-white" size={24} />
        </div>
        {trend && (
          <div
            className={`flex items-center ${
              trend > 0 ? "text-green-500" : "text-red-500"
            }`}
          >
            {trend > 0 ? (
              <ArrowUpRight size={20} />
            ) : (
              <ArrowDownRight size={20} />
            )}
            <span className="ml-1">{Math.abs(trend)}%</span>
          </div>
        )}
      </div>
      <h3 className="text-[#1C4C6B] text-sm font-medium mb-2">{title}</h3>
      <p className="text-2xl font-bold text-[#0A2A3B]">{value}</p>
    </div>
  );

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-[#0A2A3B]">
          Dashboard Overview
        </h1>
        <div className="flex items-center space-x-2 text-[#1C4C6B]">
          <Activity className="w-5 h-5" />
          <span className="text-sm">Live Updates</span>
        </div>
      </div>

      {/* Stats Grid */}
      {/* Booking Charges */}
      <div
        style={{
          flexDirection: "row-reverse",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="mb-6">
          <button
            className="bg-[#F49B23] text-white px-4 py-2 rounded shadow hover:bg-[#D27D0A]"
            onClick={() => setBookingChargesModalOpen(true)}
          >
            Update Booking Charges
          </button>
          <p
            className="mt-2 text-md text-[#000000]"
            style={{ fontWeight: "bold" }}
          >
            Current Booking Charges: {stats.bookingCharges || "Not Set"} £
          </p>
        </div>
        <div className="mb-6">
          <button
            className="bg-[#1C4C6B] text-white px-4 py-2 rounded shadow hover:bg-[#0A2A3B]"
            onClick={() => setCouponModalOpen(true)}
          >
            Add Coupon
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <StatCard
          title="Total Users"
          value={stats.users}
          icon={Users}
          trend={12}
          color="bg-[#F49B23]"
        />
        <StatCard
          title="Airports"
          value={stats.airports}
          icon={Plane}
          color="bg-[#1C4C6B]"
        />
        <StatCard
          title="Parking Slots"
          value={stats.parkingSlots}
          icon={Car}
          trend={5}
          color="bg-[#0A2A3B]"
        />
        <StatCard
          title="Total Bookings"
          value={stats.bookings}
          icon={Calendar}
          trend={8}
          color="bg-[#D27D0A]"
        />
      </div>
      <div className="bg-white rounded-lg shadow-md border border-[#D4E0E9] p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold text-[#0A2A3B]">
            Recent Bookings
          </h2>
          <TrendingUp className="text-[#1C4C6B] w-5 h-5" />
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="text-left text-sm text-[#667085] border-b border-[#D4E0E9]">
                <th className="pb-3 font-medium">Booking ID</th>
                <th className="pb-3 font-medium">User</th>
                <th className="pb-3 font-medium">Airport</th>
                <th className="pb-3 font-medium">Status</th>
                <th className="pb-3 font-medium">Amount</th>
              </tr>
            </thead>
            <tbody>
              {stats.recentBookings.map((booking) => (
                <tr
                  key={booking.id}
                  className="border-b border-[#D4E0E9] text-sm"
                >
                  <td className="py-4 text-[#1C4C6B] font-medium">
                    #{booking.id.slice(0, 8)}
                  </td>
                  <td className="py-4 text-[#667085]">
                    {booking.userName || "N/A"}
                  </td>
                  <td className="py-4 text-[#667085]">
                    {booking.airportName || "N/A"}
                  </td>
                  <td className="py-4">
                    <span
                      className={`px-2 py-1 rounded-full text-xs ${
                        booking.status === "completed"
                          ? "bg-green-100 text-green-800"
                          : booking.status === "pending"
                          ? "bg-yellow-100 text-yellow-800"
                          : "bg-red-100 text-red-800"
                      }`}
                    >
                      {booking.status || "pending"}
                    </span>
                  </td>
                  <td className="py-4 text-[#1C4C6B] font-medium">
                    ${booking.amount || "0"}
                  </td>
                </tr>
              ))}
              {stats.recentBookings.length === 0 && (
                <tr>
                  <td colSpan="5" className="py-4 text-center text-[#667085]">
                    No recent bookings found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modals */}
      {isBookingChargesModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
            <h3 className="text-lg font-bold text-[#0A2A3B] mb-4 text-center">
              Update Booking Charges
            </h3>

            {/* Error Message */}
            {chargesValidationError && (
              <p className="text-red-500 text-sm mb-2">
                {chargesValidationError}
              </p>
            )}

            <input
              type="number"
              value={bookingChargeInput}
              onChange={(e) => setBookingChargeInput(e.target.value)}
              className="border border-gray-300 p-2 w-full rounded mb-4 focus:outline-none focus:ring-2 focus:ring-[#F49B23]"
              placeholder="Enter new booking charges"
            />

            <div className="flex justify-end space-x-4">
              <button
                className="bg-[#F49B23] text-white px-4 py-2 rounded shadow hover:bg-[#d8871c] transition"
                onClick={handleUpdateBookingCharges}
              >
                Update
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded shadow hover:bg-gray-600 transition"
                onClick={() => setBookingChargesModalOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {isCouponModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
            <h3 className="text-lg font-bold text-[#0A2A3B] mb-4 text-center">
              Add Coupon
            </h3>

            {/* Error Messages */}
            {validationErrors.code && (
              <p className="text-red-500 text-sm mb-2">
                {validationErrors.code}
              </p>
            )}
            <input
              type="text"
              value={couponDetails.code}
              onChange={(e) =>
                setCouponDetails((prev) => ({ ...prev, code: e.target.value }))
              }
              className="border border-gray-300 p-2 w-full rounded mb-4 focus:outline-none focus:ring-2 focus:ring-[#1C4C6B]"
              placeholder="Enter coupon code"
            />

            {validationErrors.validity && (
              <p className="text-red-500 text-sm mb-2">
                {validationErrors.validity}
              </p>
            )}
            <input
              type="date"
              value={couponDetails.validity}
              onChange={(e) =>
                setCouponDetails((prev) => ({
                  ...prev,
                  validity: e.target.value,
                }))
              }
              className="border border-gray-300 p-2 w-full rounded mb-4 focus:outline-none focus:ring-2 focus:ring-[#1C4C6B]"
            />

            {validationErrors.discount && (
              <p className="text-red-500 text-sm mb-2">
                {validationErrors.discount}
              </p>
            )}
            <input
              type="number"
              value={couponDetails.discount}
              onChange={(e) =>
                setCouponDetails((prev) => ({
                  ...prev,
                  discount: e.target.value,
                }))
              }
              className="border border-gray-300 p-2 w-full rounded mb-4 focus:outline-none focus:ring-2 focus:ring-[#1C4C6B]"
              placeholder="Enter discount percentage"
            />

            <div className="flex justify-end space-x-4">
              <button
                className="bg-[#1C4C6B] text-white px-4 py-2 rounded shadow hover:bg-[#163a54] transition"
                onClick={handleAddCoupon}
              >
                Add Coupon
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded shadow hover:bg-gray-600 transition"
                onClick={() => setCouponModalOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
