import React, { useState, useEffect } from "react";
import { db } from "../services/firebase";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import suguThemeColors from "../theme/suguThemeColors";
import { ChevronUp, ChevronDown, Trash2, Edit, Save } from "lucide-react";

const CouponTable = () => {
  const [coupons, setCoupons] = useState([]);
  const [filteredCoupons, setFilteredCoupons] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [editingId, setEditingId] = useState(null);
  const [editFormData, setEditFormData] = useState({});

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const couponsSnap = await getDocs(collection(db, "coupons"));
        const couponsData = couponsSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCoupons(couponsData);
        setFilteredCoupons(couponsData);
      } catch (error) {
        console.error("Error fetching coupons:", error);
      }
    };

    fetchCoupons();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    const filtered = coupons.filter((coupon) =>
      coupon.code.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredCoupons(filtered);
  };

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    const sorted = [...filteredCoupons].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setSortConfig({ key, direction });
    setFilteredCoupons(sorted);
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? <ChevronUp size={16} /> : <ChevronDown size={16} />;
    }
    return null;
  };

  const handleEditClick = (coupon) => {
    setEditingId(coupon.id);
    setEditFormData({ ...coupon });
  };

  const handleSaveClick = async () => {
    const couponRef = doc(db, "coupons", editingId);
    try {
      await updateDoc(couponRef, editFormData);
      setCoupons((prev) =>
        prev.map((coupon) =>
          coupon.id === editingId ? { ...coupon, ...editFormData } : coupon
        )
      );
      setFilteredCoupons((prev) =>
        prev.map((coupon) =>
          coupon.id === editingId ? { ...coupon, ...editFormData } : coupon
        )
      );
      setEditingId(null);
    } catch (error) {
      console.error("Error updating coupon:", error);
    }
  };

  const handleDeleteClick = async (id) => {
    const couponRef = doc(db, "coupons", id);
    try {
      await deleteDoc(couponRef);
      setCoupons((prev) => prev.filter((coupon) => coupon.id !== id));
      setFilteredCoupons((prev) => prev.filter((coupon) => coupon.id !== id));
    } catch (error) {
      console.error("Error deleting coupon:", error);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6" style={{ color: suguThemeColors.primary.navy }}>
        Coupons
      </h1>

      {/* Search Bar */}
      <div className="mb-4">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search by coupon code"
          className="w-full p-3 border rounded"
          style={{
            borderColor: suguThemeColors.subtle.navy[300],
            backgroundColor: suguThemeColors.neutral.white,
            color: suguThemeColors.primary.navy,
          }}
        />
      </div>

      {/* Coupon Table */}
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead style={{ backgroundColor: suguThemeColors.table.header }}>
            <tr>
              <th
                className="p-4 text-left cursor-pointer"
                onClick={() => handleSort("code")}
                style={{ borderBottom: `1px solid ${suguThemeColors.table.border}` }}
              >
                <div className="flex items-center">
                  Coupon Code {renderSortIcon("code")}
                </div>
              </th>
              <th
                className="p-4 text-left cursor-pointer"
                onClick={() => handleSort("validity")}
                style={{ borderBottom: `1px solid ${suguThemeColors.table.border}` }}
              >
                <div className="flex items-center">
                  Validity {renderSortIcon("validity")}
                </div>
              </th>
              <th
                className="p-4 text-left cursor-pointer"
                onClick={() => handleSort("discount")}
                style={{ borderBottom: `1px solid ${suguThemeColors.table.border}` }}
              >
                <div className="flex items-center">
                  Discount (%) {renderSortIcon("discount")}
                </div>
              </th>
              <th
                className="p-4 text-left"
                style={{ borderBottom: `1px solid ${suguThemeColors.table.border}` }}
              >
                Created At
              </th>
              <th
                className="p-4 text-left"
                style={{ borderBottom: `1px solid ${suguThemeColors.table.border}` }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredCoupons.map((coupon, index) => (
              <tr
                key={coupon.id}
                style={{
                  backgroundColor:
                    index % 2 === 0
                      ? suguThemeColors.table.rowEven
                      : suguThemeColors.table.rowOdd,
                  borderBottom: `1px solid ${suguThemeColors.table.border}`,
                }}
                className="hover:bg-gray-100"
              >
                <td className="p-4">
                  {editingId === coupon.id ? (
                    <input
                      type="text"
                      value={editFormData.code}
                      onChange={(e) =>
                        setEditFormData((prev) => ({ ...prev, code: e.target.value }))
                      }
                      className="w-full border rounded"
                    />
                  ) : (
                    coupon.code
                  )}
                </td>
                <td className="p-4">
                  {editingId === coupon.id ? (
                    <input
                      type="text"
                      value={editFormData.validity}
                      onChange={(e) =>
                        setEditFormData((prev) => ({ ...prev, validity: e.target.value }))
                      }
                      className="w-full border rounded"
                    />
                  ) : (
                    coupon.validity
                  )}
                </td>
                <td className="p-4">
                  {editingId === coupon.id ? (
                    <input
                      type="number"
                      value={editFormData.discount}
                      onChange={(e) =>
                        setEditFormData((prev) => ({ ...prev, discount: e.target.value }))
                      }
                      className="w-full border rounded"
                    />
                  ) : (
                    coupon.discount
                  )}
                </td>
                <td className="p-4">{formatDate(coupon.createdAt)}</td>
                <td className="p-4 flex space-x-2">
                  {editingId === coupon.id ? (
                    <button
                      onClick={handleSaveClick}
                      className="p-2 rounded bg-green-500 text-white"
                    >
                      <Save size={16} />
                    </button>
                  ) : (
                    <button
                      onClick={() => handleEditClick(coupon)}
                      className="p-2 rounded bg-blue-500 text-white"
                    >
                      <Edit size={16} />
                    </button>
                  )}
                  <button
                    onClick={() => handleDeleteClick(coupon.id)}
                    className="p-2 rounded bg-red-500 text-white"
                  >
                    <Trash2 size={16} />
                  </button>
                </td>
              </tr>
            ))}
            {filteredCoupons.length === 0 && (
              <tr>
                <td
                  colSpan="5"
                  className="text-center p-4"
                  style={{ backgroundColor: suguThemeColors.subtle.navy[50] }}
                >
                  No coupons found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CouponTable;