import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCxDXzfTRevaC8yAK1vche7QDzOJ4DEvfg",
    authDomain: "sugu-6b245.firebaseapp.com",
    projectId: "sugu-6b245",
    storageBucket: "sugu-6b245.firebasestorage.app",
    messagingSenderId: "563749999867",
    appId: "1:563749999867:web:1b60525cd874d6317a0c5e",
    measurementId: "G-3ZJ4EZC8J0"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
