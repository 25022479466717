import React from "react";
import { BrowserRouter, Routes, Route , Navigate } from "react-router-dom";
import { ProtectedRoute, PublicRoute } from "./components/authProtector";
import Layout from "./components/layout";
import LoginScreen from "./pages/loginScreen";
import Dashboard from "./pages/dashboard";
import Airports from "./pages/airports";
import ParkingSlots from "./pages/parkingSlots";
import Users from "./pages/users";
import Bookings from "./pages/bookings";
import ParkingSlotDetailEdit from "./components/ParkingSlotDetailEdit";
import CouponTable from "./pages/coupon";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route 
          path="/" 
          element={
            <PublicRoute>
              <LoginScreen />
            </PublicRoute>
          } 
        />
        
        <Route
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/airports" element={<Airports />} />
          <Route path="/parking-slots" element={<ParkingSlots />} />
          <Route path="/users" element={<Users />} />
          <Route path="/bookings" element={<Bookings />} />
          <Route path="/coupon" element={<CouponTable />} />
          <Route path="/parking-slot-edit/:id" element={<ParkingSlotDetailEdit />} />
        </Route>
        
        {/* Catch all route for undefined paths */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;