const suguThemeColors = {
    primary: {
      navy: "#0A2A3B",
      orange: "#F49B23"
    },
    secondary: {
      lightNavy: "#1C4C6B",
      darkNavy: "#051824",
      lightOrange: "#FFB347",
      darkOrange: "#D27D0A"
    },
    subtle: {
      navy: {
        50: "#F5F7FA",  // Extremely light - perfect for table rows or backgrounds
        100: "#E9EEF3", // Light hover state for table rows
        200: "#D4E0E9", // Alternative table row background
        300: "#B8CAD9", // Borders and dividers
        400: "#9BB4C9"  // Disabled state background
      },
      orange: {
        50: "#FEF7EC",  // Very light orange for success/warning backgrounds
        100: "#FCECD4",  // Hover state for light orange elements
        200: "#FAE0B9",  // Highlighted table rows
        300: "#F7D5A0",  // Success/warning messages background
        400: "#F5C987"   // Accent backgrounds
      }
    },
    table: {
      header: "#F5F7FA",       // Table header background
      rowEven: "#FFFFFF",      // Even row background
      rowOdd: "#F5F7FA",       // Odd row background
      rowHover: "#E9EEF3",     // Row hover state
      rowSelected: "#FEF7EC",  // Selected row
      border: "#D4E0E9"        // Table borders
    },
    status: {
      success: {
        light: "#FEF7EC",
        dark: "#F49B23"
      },
      info: {
        light: "#F5F7FA",
        dark: "#1C4C6B"
      },
      warning: {
        light: "#FCECD4",
        dark: "#D27D0A"
      },
      error: {
        light: "#FEE7E7",
        dark: "#D92D20"
      }
    },
    neutral: {
      white: "#FFFFFF",
      black: "#000000",
      grey: {
        50: "#F9FAFB",
        100: "#F2F4F7",
        200: "#EAECF0",
        300: "#D0D5DD",
        400: "#98A2B3",
        500: "#667085"
      }
    }
  };
  
  // Usage examples for table styling:
  const tableStyles = {
    table: {
      borderColor: suguThemeColors.table.border,
      backgroundColor: suguThemeColors.table.rowEven
    },
    tableHeader: {
      backgroundColor: suguThemeColors.table.header,
      borderBottom: `1px solid ${suguThemeColors.table.border}`
    },
    tableRowOdd: {
      backgroundColor: suguThemeColors.table.rowOdd
    },
    tableRowEven: {
      backgroundColor: suguThemeColors.table.rowEven
    },
    tableRowHover: {
      backgroundColor: suguThemeColors.table.rowHover
    },
    tableRowSelected: {
      backgroundColor: suguThemeColors.table.rowSelected
    }
  };
  
  export default suguThemeColors;